<template>
  <div>
    <v-tabs v-model="tab" show-arrows align-with-title>
      <v-icon
        style="cursor: pointer"
        class="ml-5 mr-4"
        @click="$router.push('/prontuario-medico')"
        >mdi-keyboard-backspace</v-icon
      >

      <v-tabs-slider color="grey lighten-3"></v-tabs-slider>
      <v-tab v-for="item in items" :key="item.tab">
        {{ item.tab }}
      </v-tab>

      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="item in items"
          :key="item.content"
        >
          <v-container>
            <component
              :is="item.component"
              @alterarTab="alterarTab($event)"
            />
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import DadosPaciente from './DadosPaciente.vue'
import ListaDiagnosticos from './ListaDiagnosticos.vue'
import MainDiagnostico from './MainDiagnostico.vue'
import ReceituarioMedico from './ReceituarioMedico.vue'
//import DocumentosProntuario from './DocumentosProntuario.vue'
import HistoricoConsultas from './HistoricoConsultas.vue'
//import MainReceituario from './MainReceituario.vue'

export default {
  components: {
    DadosPaciente,
    ListaDiagnosticos,
    MainDiagnostico,
    ReceituarioMedico,
    //DocumentosProntuario,
    HistoricoConsultas,
    //MainReceituario
  },
  data() {
    return {
      tab: null,
      items: [
        {
          tab: 'Dados',
          content: 'tab-dados-paciente',
          component: 'DadosPaciente'
        },
        {
          tab: 'Diagnósticos',
          content: 'tab-diagnostico-paciente',
          component: 'MainDiagnostico'
        },
        // {
        //   tab: 'Receituário Médico',
        //   content: 'tab-receituario-medico',
        //   component: 'MainReceituario'
        // },
        // {
        //   tab: 'Documentos',
        //   content: 'tab-documento-prontuario',
        //   component: 'DocumentosProntuario'
        // },
        {
          tab: 'Histórico de Consultas',
          content: 'tab-historico-consultas',
          component: 'HistoricoConsultas'
        }
      ]
    }
  },

  methods: {
    alterarTab(tabContent) {
      if(!tabContent) return
      this.tab = this.items.findIndex(t => t.content === tabContent)
    }
  }
}
</script>
