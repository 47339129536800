import * as store from '../store/index'
import { PERFIS_PERMISSAO } from '../mixins/data/perfis'

const perfisPermissao = PERFIS_PERMISSAO

export default {
  perfilUsuario() {
    const usuario = store.default.getters['auth/user']
    if(!usuario) return null

    const perfisStore = usuario.prefil ||  usuario.perfil
    if(!perfisStore || !perfisStore.length) {
      return null
    }

    const perfis = perfisStore.map(
      p => p.descricao ? p.descricao.toUpperCase() : ''
    )

    for(const key of Object.keys(perfisPermissao)) {
      if(perfis.some(perfil => perfisPermissao[key].includes(perfil))) {
        return key
      }
    }

    return null
  }
}