<template>
  <div>
    <div class="main">
      <CrudTable
        tabelaKey="id"
        :tabelaComDetalhes="true"
        :headers="headers"
        :items="historico"
        :pagination="paginacao"
        :loading="carregando"
        :keyPaginacao="keyPaginacao"
        :itemsPerPage="15"
        @actualPage="page"
      >
        <template v-slot:top>
          <v-toolbar flat :class="$vuetify.breakpoint.xs ? 'scroll' : ''">

            <v-spacer></v-spacer>

            <ButtonMain
              @click.native="abrirFiltro()"
              text="filtrar"
              :primary="true"
            ></ButtonMain>

            <ButtonMain
              v-if="filtroAplicado"
              text="Limpar filtros"
              class="ml-2 warning"
              :primary="true"
              @click.native="
                limparFiltro()
                filtroAplicado = false
              "
            ></ButtonMain>

            <DialogConfirmation
              title="Filtros"
              :scrollable="true"
              :width="720"
              :show="dialogFiltroAberto"
              :key="keyDialogFiltro"
              :tag="'add'"
              @close="closeFiltro()"
              @confirm="
                aplicarFiltro()
                dialogFiltroAberto = false
              "
            >
              <template v-slot:body>
                <v-row class="mt-3">
                  <InputField
                    label="Nome do Médico"
                    :cols="12"
                    :valueField="filtro.nomeMedico"
                    @update:value="filtro.nomeMedico = $event"
                  />

                  <InputField
                    label="CRM"
                    :cols="6"
                    :valueField="filtro.crm"
                    @update:value="filtro.crm = $event"
                  />

                  <!-- <InputField
                    label="Especialidade"
                    :cols="6"
                    :valueField="filtro.especialidade"
                    @update:value="filtro.especialidade = $event"
                  /> -->

                  <DateField
                    cols="6"
                    label="Data da Consulta"
                    :dateFormatted="filtro.data"
                    :date="filtro.data"
                    :valueField="filtro.data"
                    :readonly="true"
                    @dateSelected="filtro.data = $event"
                  />

                  <!-- <SelectField
                    label="Status"
                    keyValue="id"
                    keyName="descricao"
                    :cols="6"
                    :valueField="filtro.status"
                    :items="listaStatus"
                    @update:value="filtro.status = $event"
                  /> -->
                </v-row>
              </template>
            </DialogConfirmation>
          </v-toolbar>
        </template>
      </CrudTable>
    </div>
  </div>
</template>

<script>
import CrudTable from '../../../components/molecules/tables/CrudTable.vue'
import DialogConfirmation from '@/components/molecules/dialogs/DialogConfirmation.vue'
import ButtonMain from '@/components/atoms/buttons/ButtonMain.vue'
import InputField from '@/components/atoms/fields/InputField.vue'
import DateField from '@/components/atoms/fields/DateField.vue'
import SelectField from '@/components/atoms/fields/SelectField.vue'

import agendaService from '@/services/AgendaService'
import globalMethods from '@/mixins/globalMethods.vue'
import utilService from '@/services/UtilService'


export default {
  components: {
    CrudTable,
    DialogConfirmation,
    ButtonMain,
    InputField,
    DateField,
    SelectField,
  },

  mixins: [globalMethods],

  data() {
    return {
      carregando: false,
      dialogFiltroAberto: false,
      filtroAplicado: false,
      keyDialogFiltro: 0,

      keyPaginacao: 0,
      paginacao: {
        page: 0,
        size: 15,
      },
      filtro: {
        nomeMedico: '',
        crm: '',
        data: '',
        //especialidade: '',
        //status: '',
      },
      dadosPaciente: {},
      historico: [],
      listaStatus: [],
      headers: [
        {
          text: 'Médico',
          value: 'medico',
          width: 350,
          sortable: false
        },
        {
          text: 'CRM',
          value: 'crm',
          align: 'center',
          sortable: false
        },
        {
          text: 'Especialidade',
          value: 'especialidade',
          align: 'center',
          sortable: false
        },
        {
          text: 'Data da Consulta',
          value: 'data',
          align: 'center',
          sortable: false
        },
        {
          text: 'Status',
          value: 'status',
          align: 'center',
          sortable: false
        }
      ]
    }
  },

  created() {
    this.getAgendaPaciente()
    this.statusAgenda()
  },

  methods: {
    statusAgenda(){
      utilService.listaStatusAgenda()
        .then(({ data }) => {
          this.listaStatus = data
        })
    },

    getAgendaPaciente() {
      this.getPacienteMedico()
        .then(({ data }) => {
          this.dadosPaciente = data
          this.historicoConsultas({ page: 0 })
        })
        .catch((err) => {
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo
          })
        })
    },

    historicoConsultas(paramentros) {
      const filtro = {
        ...paramentros || {},
        paciente: this.dadosPaciente.id,
        dependente: `${this.dadosPaciente?.idParentesco ? true : false }`,
        size: this.paginacao.size,
        ...this.filtro,
      }

      this.carregando = true
      agendaService
        .historicoConsultasPaciente(filtro)
        .then(({ data }) => {
          this.carregando = false
          this.historico = data.content.map(item => ({
            ...item,
            data: `${item?.data || ''} ${item?.hora || ''}`
          }))

          this.paginacao.total = data.totalPages
        })
        .catch((err) => {
          this.carregando = false
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo
          })
        })
    },

    resetObjeto(obj) {
      Object.keys(obj).forEach(key => obj[key] = '')
    },

    abrirFiltro() {
      this.dialogFiltroAberto = true
    },

    closeFiltro() {
      this.dialogFiltroAberto = false

      if(!this.filtroAplicado) {
        this.resetObjeto(this.filtro)
      }
    },

    limparFiltro() {
      ++this.keyPaginacao
      this.resetObjeto(this.filtro)
      this.paginacao.page = 1
      this.historicoConsultas({ page: 0 })
    },

    aplicarFiltro() {
      ++this.keyPaginacao
      this.filtroAplicado = true
      this.paginacao.page = 1
      this.historicoConsultas({ page: 0})
    },

    page(page) {
      this.paginacao.page = page - 1
      this.historicoConsultas({ page: this.paginacao.page })
    }
  }
}
</script>
