<template>
  <div>
    <div class="main">
      <DialogConfirmation
        title="Atenção!"
        :width="720"
        :show="dialogConfirmarCadastroAberto"
        :key="keyDialogConfirmarCadastro"
        :tag="'edit'"
        @close="
          dialogConfirmarCadastroAberto = false
          ++keyDialogConfirmarCadastro
        "
        @confirm="confirmarCadastro()"
      >
        <template v-slot:body>
          <h3 class="mt-5">
            Após criado, o diagnóstico não poderá ser excluído ou editado.
            Deseja continuar?
          </h3>
        </template>
      </DialogConfirmation>

      <DialogConfirmation
        title="Atenção!"
        :width="720"
        :show="dialogConfirmarAberto"
        :key="keyDialogConfirmar"
        :tag="'edit'"
        @close="
          dialogConfirmarAberto = false
          ++keyDialogConfirmar
        "
        @confirm="confirmarComentario()"
      >
        <template v-slot:body>
          <h3 v-if="modo === 'VER'" class="mt-5">
            Após adicionado, o comentário não poderá ser excluído ou editado.
            Deseja continuar?
          </h3>

          <h3 v-else class="mt-5">
            Após o diagnóstico ser criado, os comentários não poderão ser
            excluídos ou editados. Deseja continuar?
          </h3>
        </template>
      </DialogConfirmation>

      <DialogConfirmation
        :title="(parteCorpoSelecionada && parteCorpoSelecionada.nome) || ''"
        :scrollable="true"
        :width="720"
        :show="dialogComentarioAberto"
        :key="keyDialogComentario"
        :tag="'add'"
        :loading="comentando"
        @close="fecharDialogComentario()"
        @confirm="confirmarAdicaoComentario()"
      >
        <template v-slot:body>
          <div v-if="colaborador" class="info-medico ml-3">
            <div><span>Médico: </span> {{ colaborador.nome }}</div>
            <div><span>CRM: </span> {{ colaborador.numeroConselho }}</div>
          </div>

          <FormField
            :fields="fieldsComentario"
            :validate="keyValidacaoFormComentario"
          />
        </template>
      </DialogConfirmation>

      <v-row>
        <div class="form-container col-xl-7 col-lg-7 col-12">
          <v-row>
            <v-col>
              <TitleH2 class="mt-3 mb-5 mx-3" title="Dados do Paciente" />

              <v-form
                ref="formSelects"
                :class="modo !== 'VER' ? 'mb-5' : 'mb-1'"
              >
                <v-row>
                  <v-col cols="12" :md="modo !== 'VER' ? 6 : 12">
                    <SelectField
                      v-if="modo !== 'VER'"
                      ref="selectSecaoProntuarioMedico"
                      label="Seções do Prontuário"
                      keyName="descricao"
                      keyValue="id"
                      :items="secoes"
                      :clearable="false"
                      :rules="
                        modo !== 'VER'
                          ? [(value) => !!value || validadores.obrigatorio]
                          : []
                      "
                      @change="adicionarChip($event, secoes, chipsSecao)"
                    />

                    <h3 class="ml-5" v-else>Seções do Prontuário</h3>

                    <div v-if="chipsSecao.length" class="container-chips">
                      <v-chip-group active-class="primary--text" column>
                        <v-chip
                          v-for="chip in chipsSecao"
                          class="custom-chip-prontuario"
                          color="#E9F4FF"
                          text-color="#1976D2"
                          close
                          :key="chip.id"
                          :disabled="modo === 'VER'"
                          @click:close="removerChip(chip, chipsSecao)"
                        >
                          {{ chip.descricao }}
                        </v-chip>
                      </v-chip-group>
                    </div>
                  </v-col>

                  <v-col cols="12" :md="modo !== 'VER' ? 6 : 12">
                    <AutocompletePaginadoMain
                      v-if="modo !== 'VER'"
                      tituloItem="label"
                      placeholder="Pesquise por código"
                      keyValue="id"
                      mensagemItemNaoEncontrado="Nenhum CID encontrada"
                      :cols="12"
                      :exibirAvatar="false"
                      :comTemplate="false"
                      :filled="false"
                      :outlined="true"
                      :clearable="false"
                      :items="CIDS"
                      :keyName="'label'"
                      :rules="
                        modo !== 'VER'
                          ? [(value) => !!value || validadores.obrigatorio]
                          : []
                      "
                      @change="adicionarChip($event, CIDS, chipsCID)"
                      @pesquisa="buscarCID($event)"
                      @scrollDown="adicionarCIDs()"
                      @onBlur="$event && $event.clear && receberCID()"
                      @onClear="$event && $event.clear && receberCID()"
                    ></AutocompletePaginadoMain>

                    <h3 class="ml-5" v-else>CIDs</h3>

                    <div v-if="chipsCID.length" class="container-chips">
                      <v-chip-group active-class="primary--text" column>
                        <v-chip
                          v-for="chip in chipsCID"
                          class="custom-chip-prontuario"
                          color="#E9F4FF"
                          text-color="#1976D2"
                          close
                          :key="chip.id"
                          :disabled="modo === 'VER'"
                          @click:close="removerChip(chip, chipsCID)"
                        >
                          {{ chip.codigo }} - {{ chip.descricao }}
                        </v-chip>
                      </v-chip-group>
                    </div>
                  </v-col>
                </v-row>
              </v-form>

              <FormField :fields="fields" :validate="keyValidacaoForm" />
            </v-col>
          </v-row>

          <div v-if="listaComentarios.length">
            <h3 class="mb-4 ml-1">Comentários</h3>
            <v-row>
              <v-col
                v-for="item in listaComentarios"
                :key="item.idParteDoCorpo"
                :class="{
                  'col-xl-6 col-lg-6 col-md-6 col-12 mb-1': !item.detalheAberto,
                  'col-12 mb-1': item.detalheAberto
                }"
              >
                <div
                  class="painel-comentario"
                  :class="{
                    'painel-aberto': item.detalheAberto
                  }"
                >
                  <div class="painel-header" @click="detalharComentario(item)">
                    {{ item.descricaoParteDoCorpo }}
                    <div class="icone">
                      <v-icon color="#1976d2">mdi-chevron-down-circle</v-icon>
                    </div>
                  </div>

                  <div class="painel-detalhes">
                    <div
                      v-for="comentario in item.comentario"
                      :key="comentario.id"
                      class="comentario"
                    >
                      {{ comentario.comentario }}

                      <div
                        v-if="comentario.itens.length"
                        class="container-itens-detalhes"
                      >
                        <div
                          v-for="detalhe in comentario.itens"
                          :key="detalhe.chave"
                          class="item-detalhes"
                        >
                          <span class="detalhe-chave">
                            {{ detalhe.chave }}:
                          </span>
                          <span>
                            {{ detalhe.valor }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>

        <div class="corpo-container col-xl-5 col-lg-5 col-12">
          <div class="controles mr-2">
            <button
              class="btn-controle mr-2"
              :class="{ 'modo-ativo': modoSVGCorpo.vista === 'FRENTE' }"
              @click="alternarModoApresentacaoCorpo('FRENTE')"
            >
              Frente
            </button>

            <button
              class="btn-controle"
              :class="{ 'modo-ativo': modoSVGCorpo.vista === 'TRAS' }"
              @click="alternarModoApresentacaoCorpo('TRAS')"
            >
              Costa
            </button>
          </div>

          <div
            :class="{
              'svg-container': true,
              'pointer-events-none': this.perfilUsuario !== 'medico'
            }"
          >
            <div
              v-if="modoSVGCorpo.vista === 'FRENTE'"
              class="corpo-vista-frontal"
            >
              <svg
                v-if="modoSVGCorpo.corpo === 'MASCULINO'"
                class="svg-corpo"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="384px"
                viewBox="0 0 272 510"
                xml:space="preserve"
              >
                <path
                  v-for="parte in partesFrenteMasculina"
                  :key="parte.id"
                  vector-effect="non-scaling-stroke"
                  :id="parte.id"
                  :d="parte.d"
                  :fill="parte.fill"
                  :stroke="parte.stroke"
                  :stroke-miterlimit="parte['stroke-miterlimit']"
                  :class="{
                    'parte-selecionada': parte.selecionada,
                    'parte-destacada': parte.destacada
                  }"
                  @click="selecionarParte(parte)"
                  @mouseenter="parteCorpoMouseEnter = parte"
                  @mouseleave="parteCorpoMouseEnter = undefined"
                ></path>
              </svg>

              <svg
                v-if="modoSVGCorpo.corpo === 'FEMININO'"
                class="svg-corpo"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="384px"
                viewBox="0 0 272 510"
                xml:space="preserve"
              >
                <path
                  v-for="parte in partesFrenteFeminina"
                  :key="parte.id"
                  vector-effect="non-scaling-stroke"
                  :id="parte.id"
                  :d="parte.d"
                  :fill="parte.fill"
                  :stroke="parte.stroke"
                  :stroke-miterlimit="parte['stroke-miterlimit']"
                  :class="{
                    'parte-selecionada': parte.selecionada,
                    'parte-destacada': parte.destacada
                  }"
                  @click="selecionarParte(parte)"
                  @mouseenter="parteCorpoMouseEnter = parte"
                  @mouseleave="parteCorpoMouseEnter = undefined"
                ></path>
              </svg>
            </div>

            <div
              v-if="modoSVGCorpo.vista === 'TRAS'"
              class="corpo-vista-traseira"
            >
              <svg
                v-if="modoSVGCorpo.corpo === 'MASCULINO'"
                class="svg-corpo"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="384px"
                viewBox="0 0 272 510"
                xml:space="preserve"
              >
                <path
                  v-for="parte in partesTrasMasculina"
                  :key="parte.id"
                  vector-effect="non-scaling-stroke"
                  :id="parte.id"
                  :d="parte.d"
                  :fill="parte.fill"
                  :stroke="parte.stroke"
                  :stroke-miterlimit="parte['stroke-miterlimit']"
                  :class="{
                    'parte-selecionada': parte.selecionada,
                    'parte-destacada': parte.destacada
                  }"
                  @click="selecionarParte(parte)"
                  @mouseenter="parteCorpoMouseEnter = parte"
                  @mouseleave="parteCorpoMouseEnter = undefined"
                ></path>
              </svg>

              <svg
                v-if="modoSVGCorpo.corpo === 'FEMININO'"
                class="svg-corpo"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="384px"
                viewBox="0 0 272 510"
                xml:space="preserve"
              >
                <path
                  v-for="parte in partesTrasFeminina"
                  :key="parte.id"
                  vector-effect="non-scaling-stroke"
                  :id="parte.id"
                  :d="parte.d"
                  :fill="parte.fill"
                  :stroke="parte.stroke"
                  :stroke-miterlimit="parte['stroke-miterlimit']"
                  :class="{
                    'parte-selecionada': parte.selecionada,
                    'parte-destacada': parte.destacada
                  }"
                  @click="selecionarParte(parte)"
                  @mouseenter="parteCorpoMouseEnter = parte"
                  @mouseleave="parteCorpoMouseEnter = undefined"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
import { corpoHumano } from './../../../mixins/data/corpoHumano.js'
import globalMethods from './../../../mixins/globalMethods.vue'
import TitleH2 from '@/components/atoms/title/TitleH2.vue'
import InputField from '@/components/atoms/fields/InputField.vue'
import SelectField from '@/components/atoms/fields/SelectField.vue'
import InputMaskField from '@/components/atoms/fields/InputMaskField.vue'
import AutocompletePaginadoMain from '@/components/atoms/fields/AutocompletePaginadoMain.vue'
import TextAreaField from '@/components/atoms/fields/TextAreaField.vue'
import agendaService from '@/services/AgendaService'
import utilService from '@/services/UtilService'
import authService from '@/services/AuthService'
import colaboradorService from '@/services/ColaboradorService'
import ButtonMain from '@/components/atoms/buttons/ButtonMain.vue'
import FormField from '@/components/atoms/forms/FormField.vue'
import DialogConfirmation from '@/components/molecules/dialogs/DialogConfirmation.vue'

export default {
  name: 'DiagnosticoPaciente',
  props: {
    modo: {
      type: String,
      required: true,
      default: 'VER'
    },
    diagnostico: {
      type: Object,
      default: {}
    },
    paciente: {
      type: Object,
      default: {}
    },
    validar: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  components: {
    ButtonMain,
    InputField,
    SelectField,
    InputMaskField,
    TextAreaField,
    FormField,
    TitleH2,
    DialogConfirmation,
    AutocompletePaginadoMain
  },

  mixins: [corpoHumano, globalMethods],

  data() {
    return {
      partesFrenteMasculina: this.PARTES_FRENTE_MASCULINA,
      partesTrasMasculina: this.PARTES_TRAS_MASCULINA,
      partesFrenteFeminina: this.PARTES_FRENTE_FEMININA,
      partesTrasFeminina: this.PARTES_TRAS_FEMININA,
      parteCorpoSelecionada: null,

      comentarioParteCorpo: '',
      pesquisaCID: '',
      perfilUsuario: authService.perfilUsuario(),

      keyValidacaoForm: 0,
      keyValidacaoFormComentario: 30,
      keyDialogConfirmar: 0,
      keyDialogConfirmarCadastro: 30,
      keyDialogComentario: 60,
      paginaAtualSelectCID: 0,
      totalPagesSelectCID: 0,
      dialogComentarioAberto: false,
      dialogConfirmarAberto: false,
      dialogConfirmarCadastroAberto: false,
      comentando: false,
      secoes: [],
      CIDS: [],
      chipsSecao: [],
      chipsCID: [],
      listaComentarios: [],
      listaComentariosLocais: [],
      colaborador: null,
      pacienteSlug: null,

      modoSVGCorpo: {
        vista: 'FRENTE',
        corpo: this.setSexoCorpo(this.paciente)
      },
      validadores: {
        obrigatorio: 'Campo obrigatório',
        invalido: 'Campo inválido'
      },
      fieldsComentario: {
        comentario: {
          label: 'Comentário',
          valueField: null,
          value: '',
          cols: 12,
          field: 'TextAreaField',
          maxLength: 250,
          rows: 6,
          rules: [(value) => !!value || this.validadores.obrigatorio]
        }
      },
      fields: {
        relatoPaciente: {
          label: 'Relato do Paciente',
          valueField: null,
          value: '',
          cols: 12,
          field: 'TextAreaField',
          maxLength: 250,
          rows: 4
          //rules: [(value) => !!value || this.validadores.obrigatorio]
        },

        pa: {
          label: 'P.A.',
          valueField: null,
          cols: 4,
          value: '',
          field: 'InputField'
          //rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        pulso: {
          label: 'Pulso (ppm)',
          valueField: null,
          cols: 4,
          value: '',
          field: 'InputField',
          type: 'number'
          //rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        temperatura: {
          label: 'Temperatura (ºC)',
          valueField: null,
          cols: 4,
          value: '',
          field: 'InputField',
          type: 'number'
          //rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        peso: {
          label: 'Peso (kg)',
          valueField: null,
          cols: 4,
          value: '',
          field: 'InputField',
          type: 'number'
          //rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        altura: {
          label: 'Altura (cm)',
          valueField: null,
          cols: 4,
          value: '',
          field: 'InputField',
          type: 'number'
          //rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        circuferenciaAbdominal: {
          label: 'Circunferência Abdominal (cm)',
          valueField: null,
          cols: 4,
          value: '',
          field: 'InputField',
          type: 'number'
          //rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        auscultaCardiaca: {
          label: 'Ausculta Cardíaca',
          valueField: null,
          value: '',
          cols: 12,
          field: 'TextAreaField',
          maxLength: 250,
          rows: 4
          //rules: [(value) => !!value || this.validadores.obrigatorio]
        },

        auscultaPulmonar: {
          label: 'Ausculta Pulmonar',
          valueField: null,
          value: '',
          cols: 12,
          field: 'TextAreaField',
          maxLength: 250,
          rows: 4
          //rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        diagnostico: {
          label: 'Diagnóstico Clínico',
          valueField: null,
          value: '',
          cols: 12,
          field: 'TextAreaField',
          maxLength: 4000,
          rows: 4
          //rules: [(value) => !!value || this.validadores.obrigatorio]
        }
      }
    }
  },

  created() {
    this.partesFrenteMasculina = this.PARTES_FRENTE_MASCULINA
    this.partesTrasMasculina = this.PARTES_TRAS_MASCULINA
    this.partesFrenteFeminina = this.PARTES_FRENTE_FEMININA
    this.partesTrasFeminina = this.PARTES_TRAS_FEMININA
    this.pacienteSlug = this.getPacienteSlug()

    this.receberSecoes()
    this.receberCID()

    if (this.perfilUsuario === 'medico') {
      this.dodosDoColaborador()
    }
  },

  mounted() {
    if (this.modo === 'VER') {
      this.popularFormulario(this.diagnostico)
    }
  },

  methods: {
    popularFormulario(data) {
      const replaces = [
        { key: 'auscutaPulmonar', replace: 'auscultaPulmonar' },
        { key: 'auscutaCardiaca', replace: 'auscultaCardiaca' },
        { key: 'diagnosticoClinico', replace: 'diagnostico' },
        { key: 'pressaoArterial', replace: 'pa' },
        { key: 'abdomen', replace: 'circuferenciaAbdominal' }
      ]

      Object.entries(data).map(([key, value]) => {
        replaces.forEach((r) => {
          if (r.key === key) {
            this.fields[r.replace].valueField = value
          } else if (this.fields[key]) {
            this.fields[key].valueField = value
          }
        })
      })

      Object.keys(this.fields).map((key) => {
        this.fields[key]['readonly'] = true
        this.fields[key]['rules'] = []
      })

      this.chipsCID = data.cids
      this.chipsSecao = data.secoes
    },

    receberCID(paramentros = {}) {
      const filtro = { ...paramentros, size: 20 }
      utilService.cid(filtro).then(({ data }) => {
        this.totalPagesSelectCID = data.totalPages
        this.CIDS = data.content.map((item) => ({
          ...item,
          label: `${item.codigo} - ${item.descricao}`
        }))
      })
    },

    buscarCID(termo) {
      if (!termo.length) {
        this.pesquisaCID = ''
        this.paginaAtualSelectCID = 0
        this.receberCID()
        return
      }

      if (termo !== this.pesquisaCID) {
        this.paginaAtualSelectCID = 0
        this.CIDS = []
      }

      this.pesquisaCID = termo
      this.receberCID({ codigo: this.pesquisaCID })
    },

    adicionarCIDs() {
      if (this.paginaAtualSelectCID < this.totalPagesSelectCID) {
        ++this.paginaAtualSelectCID

        const paginacao = {
          descricao: this.pesquisaCID,
          page: this.paginaAtualSelectCID,
          size: 20
        }

        utilService.cid(paginacao).then(({ data }) => {
          this.totalPagesSelectCID = data.totalPages

          if (data.numberOfElements <= 0) {
            this.paginaAtualSelectCID = 0
            return
          }

          this.CIDS = [
            ...this.CIDS,
            ...data.content.map((item) => ({
              ...item,
              label: `${item.codigo} - ${item.descricao}`
            }))
          ]
        })
      }
    },

    receberSecoes() {
      utilService
        .secoesProntuario()
        .then(({ data }) => {
          this.secoes = data
        })
        .catch((err) => {
          this.$root.vtoast.show({
            status: 'error',
            text: err?.response?.data.mensagemUsuario,
            title: err?.response?.data.titulo
          })
        })
    },

    setSexoCorpo(paciente) {
      if (!paciente.idSexo) {
        this.$root.vtoast.show({
          status: 'error',
          title: 'Sexo do paciente não identificado',
          text: 'Por favor, atualize os dados do paciente',
          time: 60000
        })
        this.$emit('closeDialog')
        return
      }

      if (paciente.idSexo === 1) return 'MASCULINO'
      if (paciente.idSexo === 2) return 'FEMININO'
      return 'MASCULINO'
    },

    adicionarChip(field, listaSelect, selecionados) {
      if (field && !selecionados.find((item) => item.id === field)) {
        const itemEncontrado = listaSelect.find((item) => item.id === field)
        if (itemEncontrado) {
          selecionados.push(itemEncontrado)
        }
      }
    },

    removerChip(chip, selecionados) {
      const chipIndex = selecionados.indexOf(chip)
      if (chipIndex >= 0) {
        selecionados.splice(chipIndex, 1)
      }
    },

    toastDadoNaoEncontrado(mensagem, tipoAviso) {
      this.$root.vtoast.show({
        status: tipoAviso || 'error',
        title: 'Atenção!',
        text: mensagem,
        time: 25000
      })
    },

    alternarModoApresentacaoCorpo(modo) {
      this.parteCorpoSelecionada = null
      this.setValorParaTodosCorpos('selecionada', false)
      this.modoSVGCorpo.vista = modo
    },

    selecionarParte(parte) {
      if (this.perfilUsuario !== 'medico') return

      if (this.modo === 'VER') {
        const mensagem = `Crie um novo diagnóstico para poder adicionar comentário`
        this.toastDadoNaoEncontrado(mensagem, 'warning')
        return
      }

      if (this.modoSVGCorpo.vista === 'FRENTE') {
        this.modoSVGCorpo.corpo === 'MASCULINO'
          ? this.partesFrenteMasculina.forEach(
              (item) => (item.selecionada = false)
            )
          : this.partesFrenteFeminina.forEach(
              (item) => (item.selecionada = false)
            )

        parte.selecionada = true
      } else if (this.modoSVGCorpo.vista === 'TRAS') {
        this.modoSVGCorpo.corpo === 'MASCULINO'
          ? this.partesTrasMasculina.forEach(
              (item) => (item.selecionada = false)
            )
          : this.partesTrasFeminina.forEach(
              (item) => (item.selecionada = false)
            )

        parte.selecionada = true
      }

      this.parteCorpoSelecionada = parte
      this.abrirDialogComentario()
    },

    abrirDialogComentario() {
      this.comentarioParteCorpo = ''

      if (!this.colaborador) {
        this.toastDadoNaoEncontrado(
          'Colaborador não encontrado. Por favor, refaça seu login.'
        )
        this.dialogComentarioAberto = false
        return
      }

      this.dialogComentarioAberto = true
    },

    fecharDialogComentario() {
      this.dialogComentarioAberto = false
      ++this.keyDialogComentario
      this.setValorParaTodosCorpos('selecionada', false)
    },

    dodosDoColaborador() {
      const cpf = this.$store.getters['auth/cpf']

      if (!cpf) {
        this.toastDadoNaoEncontrado(
          'CPF do colaborador não encontrado. Por favor, refaça seu login.'
        )
        return
      }

      colaboradorService
        .detalharColaboradorPorCpf(cpf)
        .then(({ data }) => {
          this.colaborador = data
          this.comentarios()
        })
        .catch((err) => {
          this.$root.vtoast.show({
            status: 'error',
            text: err?.response?.data.mensagemUsuario,
            title: err?.response?.data.titulo
          })
        })
    },

    comentarios() {
      if (!this.checkPacienteSlug()) return

      const filtro = {
        dependente: `${this.pacienteSlug.dependente}`,
        medico: this.colaborador?.id || null
      }
      agendaService
        .comentariosPorParteCorpoPaciente(this.paciente.id, filtro)
        .then(({ data }) => {
          this.listaComentarios = data.map((item) => ({
            ...item,
            detalheAberto: false,
            descricaoParteDoCorpo: this.setNomeParteCorpo(item),
            comentario: item.comentario.map((c) => ({
              ...c,
              itens: [
                { chave: 'Médico', valor: c.medico },
                { chave: 'CRM', valor: c.crm },
                { chave: 'Data', valor: c.data },
                { chave: 'Nº do Diagnóstico', valor: c.diagnostico }
              ]
            }))
          }))
        })
        .catch((err) => {
          this.$root.vtoast.show({
            status: 'error',
            text: err?.response?.data.mensagemUsuario,
            title: err?.response?.data.titulo
          })
        })
    },

    setNomeParteCorpo(parte) {
      if (!parte || !parte.referencia) return ''

      const referencia = parte.referencia.toLowerCase()
      if (referencia.includes('parte-tras-')) {
        return `${parte.descricaoParteDoCorpo || parte.descricao} - COSTA`
      }

      if (referencia.includes('parte-frente-')) {
        return `${parte.descricaoParteDoCorpo || parte.descricao} - FRENTE`
      }
    },

    confirmarAdicaoComentario() {
      if (
        this.verificarSeHaCampoObrigatorioNulo(
          this.fieldsComentario,
          this.validadores
        )
      ) {
        ++this.keyValidacaoFormComentario
        this.toastDadoNaoEncontrado('Dados Incompletos')
        return
      }

      this.dialogConfirmarAberto = true
    },

    confirmarComentario() {
      this.dialogConfirmarAberto = false

      if (!this.paciente?.idSexo) {
        this.toastDadoNaoEncontrado('Sexo do corpo não encontrado')
        return
      }

      utilService
        .partesCorpoPorSexo(this.paciente.idSexo)
        .then(({ data }) => {
          const parte = data.find(
            (item) =>
              item.referencia.toUpperCase() ===
              this.parteCorpoSelecionada.referencia.toUpperCase()
          )

          if (!parte) {
            this.toastDadoNaoEncontrado('Parte do corpo não encontrado')
            return
          }

          if (this.modo === 'VER') {
            this.adicionarComentarioAPI(parte)
            return
          }

          this.adicionarComentarioLocal(parte, this.parteCorpoSelecionada)
        })
        .catch((err) => {
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo
          })
        })
    },

    adicionarComentarioAPI(parteCorpo) {
      const body = {
        comentario: this.fieldsComentario['comentario'].value,
        diagnostico: this.diagnostico.id,
        parteDoCorpo: parteCorpo.id,
        colaborador: this.colaborador.id
      }

      this.comentando = true
      agendaService
        .comentarParteCorpoDiagnostico(body)
        .then(() => {
          this.comentando = false
          this.dialogComentarioAberto = false
          ++this.keyDialogComentario
          this.comentarios()
        })
        .catch((err) => {
          this.comentando = false
          this.$root.vtoast.show({
            status: 'error',
            text: err?.response?.data.mensagemUsuario,
            title: err?.response?.data.titulo
          })
        })
    },

    adicionarComentarioLocal(parteCorpoEncontrada, parteCorpoSelecionada) {
      const comentario = {
        comentario: this.fieldsComentario['comentario'].value,
        diagnostico: this.diagnostico.id,
        parteDoCorpo: parteCorpoEncontrada.id,
        idLocal: parteCorpoEncontrada.id,

        medico: this.colaborador.nome,
        crm: this.colaborador.numeroConselho,
        itens: [
          { chave: 'Médico', valor: this.colaborador.nome },
          { chave: 'CRM', valor: this.colaborador.numeroConselho }
        ]
      }

      if (
        !this.listaComentarios.length &&
        !this.listaComentariosLocais.length
      ) {
        this.listaComentariosLocais.push({
          ...parteCorpoEncontrada,
          idParteDoCorpo: parteCorpoEncontrada.id,
          descricaoParteDoCorpo: this.setNomeParteCorpo(parteCorpoEncontrada),
          detalheAberto: true,
          comentario: [comentario]
        })

        this.listaComentarios = this.listaComentariosLocais
        this.dialogComentarioAberto = false
        ++this.keyDialogComentario
        return
      }

      const index = this.listaComentarios.findIndex(
        (item) => item.idParteDoCorpo === parteCorpoEncontrada.id
      )

      if (index >= 0) {
        this.listaComentarios[index].comentario.unshift(comentario)
      } else {
        this.listaComentarios.push({
          ...parteCorpoEncontrada,
          idParteDoCorpo: parteCorpoEncontrada.id,
          descricaoParteDoCorpo: this.setNomeParteCorpo(parteCorpoEncontrada),
          detalheAberto: false,
          comentario: [comentario]
        })
      }

      this.dialogComentarioAberto = false
      ++this.keyDialogComentario

      this.detalharComentario(parteCorpoSelecionada)
    },

    detalharComentario(parteCorpo) {
      parteCorpo.detalheAberto = !parteCorpo.detalheAberto
    },

    getValueField(key) {
      if (!key || !this.fields[key]) return null

      const field = this.fields[key]
      if (field?.type === 'number') {
        const num = Number(field.value)
        return !Number.isNaN(num) && num > 0 ? num : null
      }

      return field?.value || null
    },

    setValorParaTodosCorpos(key, value) {
      const fun = (item) => (item[key] = value)

      this.partesFrenteMasculina.forEach(fun)
      this.partesTrasMasculina.forEach(fun)
      this.partesFrenteFeminina.forEach(fun)
      this.partesTrasFeminina.forEach(fun)
    },

    checkPacienteSlug() {
      if (!this.pacienteSlug) {
        this.toastDadoNaoEncontrado(
          'Paciente não encontrado. Por favor volte na lista de pacientes e recarregue a página.'
        )
        return false
      }

      return true
    },

    confirmarCadastro() {
      if (!this.checkPacienteSlug()) return

      const comentarios = []
      this.listaComentarios.forEach((c) => {
        const filtro = c.comentario.filter((item) => !!item.idLocal)
        comentarios.push(
          ...filtro.map((c) => ({
            comentario: c.comentario,
            parteDoCorpo: c.parteDoCorpo
          }))
        )
      })

      const body = {
        colaborador: this.colaborador.id,
        dependente: this.pacienteSlug.dependente ? this.pacienteSlug.id : null,
        associado: this.pacienteSlug.dependente ? null : this.pacienteSlug.id,

        relatoPaciente: this.getValueField('relatoPaciente'),
        pressaoArterial: this.getValueField('pa'),
        pulso: this.getValueField('pulso'),
        temperatura: this.getValueField('temperatura'),
        peso: this.getValueField('peso'),
        altura: this.getValueField('altura'),
        abdomen: this.getValueField('circuferenciaAbdominal'),
        auscutaCardiaca: this.getValueField('auscultaCardiaca'),
        auscutaPulmonar: this.getValueField('auscultaPulmonar'),
        diagnosticoClinico: this.getValueField('diagnostico'),
        secao: this.chipsSecao.map((item) => ({ id: item.id })),
        cid: this.chipsCID.map((item) => ({ id: item.id })),
        comentario: comentarios
      }

      this.$emit('formValue', body)
      this.dialogConfirmarCadastroAberto = false
      ++this.keyDialogConfirmarCadastro
    }
  },

  watch: {
    validar: {
      immediate: false,
      handler(newValue) {
        if (newValue) {
          if (!this.chipsCID.length || !this.chipsSecao.length) {
            this.toastDadoNaoEncontrado(
              'Lista de CIDs ou Seções não podem estar vazias'
            )
            return
          }

          if (!this.colaborador) {
            this.toastDadoNaoEncontrado(
              'Colaborador não encontrado. Por favor, refaça seu login.'
            )
            return
          }

          if (
            !this.$refs[`formSelects`].validate() ||
            this.verificarSeHaCampoObrigatorioNulo(
              this.fields,
              this.validadores
            )
          ) {
            ++this.keyValidacaoForm
            this.$root.vtoast.show({
              status: 'error',
              title: 'Erro!',
              text: 'Dados Incompletos'
            })

            return
          }

          this.dialogConfirmarCadastroAberto = true
        }
      }
    }

    // listaComentarios: {
    //   immediate: false,
    //   handler(newValue) {
    //     if(newValue) {
    //       this.setValorParaTodosCorpos('destacada', false)

    //       newValue.forEach(comentario => {
    //         if(comentario.referencia) {
    //           const refComentario = comentario.referencia.toLowerCase();
    //           this.partesFrenteMasculina.forEach(parte => {
    //             const refParte = parte.referencia.toLowerCase()
    //             if(refComentario === refParte) parte.destacada = true
    //           })

    //           this.partesTrasMasculina.forEach(parte => {
    //             const refParte = parte.referencia.toLowerCase()
    //             if(refComentario === refParte) parte.destacada = true
    //           })

    //           this.partesFrenteFeminina.forEach(parte => {
    //             const refParte = parte.referencia.toLowerCase()
    //             if(refComentario === refParte) parte.destacada = true
    //           })

    //           this.partesTrasFeminina.forEach(parte => {
    //             const refParte = parte.referencia.toLowerCase()
    //             if(refComentario === refParte) parte.destacada = true
    //           })
    //         }
    //       })
    //     }
    //   }
    // }
  }
}
</script>

<style scoped>
.main {
  margin-bottom: 5rem;
}

.corpo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.controles {
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.controles .btn-controle {
  background-color: #fff;
  color: #414c5e;
  height: initial;
  font-weight: bold;
  padding: 0.4rem 1.8rem;
  border: 1px solid #cfcfcf;
  border-radius: 10px;
}

.controles .btn-controle.modo-ativo {
  background-color: #e9f4ff;
}

.svg-container {
  background-color: #e9f4ff;
  border-radius: 0.3rem;
  width: 100%;
  padding: 2rem 0;
}

.svg-corpo {
  cursor: pointer;
  transition: fill 0.2s ease, fill-opacity 0.2s ease;
}

.svg-corpo path {
  transition: 0.2s ease;
}

.svg-corpo path:hover {
  fill: #69b0e9 !important;
}

.svg-corpo path.parte-destacada {
  fill: #ffa3a3 !important;
}

.svg-corpo path.parte-selecionada {
  fill: #2196f3 !important;
}

.container-chips {
  padding-left: 1rem;
}

.info-medico > div > span {
  color: #2196f3;
  font-size: 1rem;
}

.painel-comentario {
  background-color: #e9f4ff;
  display: flex;
  flex-direction: column;
  padding: 0.7rem 1.2rem;
  border-radius: 10px;
  transition: 0.3s ease;
}
.painel-comentario .painel-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0.2rem 0 0.4rem;
  font-size: 1.1rem;
  cursor: pointer;
}
.painel-comentario .painel-header .icone {
  transition: 0.3s ease;
}
.painel-comentario.painel-aberto .painel-header .icone {
  transform: rotate(-180deg);
}
.painel-comentario .painel-detalhes {
  height: 0;
  overflow: hidden;
  transition: 0.3s ease;
}
.painel-comentario.painel-aberto .painel-detalhes {
  height: 230px;
  overflow: auto;
}

.painel-comentario.painel-aberto .painel-detalhes .comentario {
  padding: 0.5rem 0;
  margin-right: 1.2rem;
}

.painel-comentario.painel-aberto .painel-detalhes .comentario:not(:last-child) {
  border-bottom: 1px solid #2c81d654;
}
.painel-comentario .container-itens-detalhes {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}
.painel-comentario .container-itens-detalhes .item-detalhes {
  display: flex;
}
.painel-comentario .container-itens-detalhes .item-detalhes .detalhe-chave {
  color: #1976d2 !important;
  font-weight: bold;
  margin-right: 0.5rem;
}
</style>
