<template>
  <div>
    <div class="d-flex mb-5">
      <span v-if="detalhandoItem" class="voltar ml-3" @click="voltaParaLista()">
        <v-icon class="icone"> mdi-arrow-left </v-icon>
        Voltar para Lista de Diagnósticos
      </span>
    </div>

    <DiagnosticoPaciente
      v-if="detalhandoItem"
      :modo="modoDetalhesDiagnostico"
      :diagnostico="diagnostico"
      :paciente="paciente"
      :validar="false"
    />

    <ListaDiagnosticos
      v-else
      @detalhar="detalharItem($event)"
    />
  </div>
</template>

<script>
import ListaDiagnosticos from './ListaDiagnosticos.vue'
import DiagnosticoPaciente from './DiagnosticoPaciente.vue'
import ButtonMain from '@/components/atoms/buttons/ButtonMain.vue'
import TooltipMain from '@/components/atoms/TooltipMain.vue'
import globalMethods from "@/mixins/globalMethods.vue";

export default {
  components: {
    ListaDiagnosticos,
    DiagnosticoPaciente,
    ButtonMain,
    TooltipMain
  },
  data() {
    return {
      detalhandoItem: false,
      modoDetalhesDiagnostico: 'VER',
      paciente: {},
      diagnostico: {}
    }
  },

  mixins: [globalMethods],

  created() {
    this.dadosPaciente()
  },

  methods: {
    dadosPaciente() {
      this.getPacienteMedico()
        .then(({ data }) => {
          this.paciente = data
        })
        .catch((err) => {
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo
          })
        })
    },

    detalharItem(item) {
      this.modoDetalhesDiagnostico = 'VER'
      this.detalhandoItem = true
      this.diagnostico = item
    },

    voltaParaLista() {
      this.detalhandoItem = false
      this.diagnostico = {}
    }
  }
}
</script>

<style scoped>
.voltar {
  color: #1976d2;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.voltar .icone {
  color: inherit;
  margin-right: 0.3rem;
}
</style>
