<template>
  <v-container>
    <CrudTable
      tabelaKey="id"
      :tabelaComDetalhes="true"
      :headers="headers"
      :items="receituario"
      :pagination="paginacao"
      :loading="carregando"
      :keyPaginacao="keyPaginacao"
      @actualPage="page"
    >
      <template v-slot:[`item.actions`]="{ item }">

      </template>
    </CrudTable>
  </v-container>
</template>

<script>
import CrudTable from '@/components/molecules/tables/CrudTable'

export default {
  comments: {
    CrudTable
  },

  data() {
    return {
      keyPaginacao: 0,

      carregando: false,

      paginacao: {
        page: 0,
        size: 10,
        total: 0
      },

      receituario: [],
       headers: [
        {
          text: 'Categoria',
          value: 'categoria',
          sortable: false
        },
        { text: 'Industria/Marca', value: 'marca', align: 'center', sortable: false },
        { text: 'Medicamento', value: 'medicamento', align: 'center', sortable: false },
        { text: 'Qtd de Embalagens', value: 'quantidade', align: 'center', sortable: false },
        { text: 'Ações', value: 'actions', align: 'center', width: 150, sortable: false }
      ],
    }
  },

  methods: {
    page(page) {
      this.carregando = true
      this.paginacao.page = page - 1
      const paginacao = { page: this.paginacao.page, size: this.itensPorPagina }
      this.carregando = false
    }
  }
}
</script>