<template>
  <v-container>
    <CrudTable
      tabelaKey="id"
      :tabelaComDetalhes="true"
      :headers="headers"
      :items="diagnosticos"
      :pagination="paginacao"
      :loading="carregando"
      :keyPaginacao="keyPaginacao"
      :itemsPerPage="15"
      @actualPage="page"
      @clickRow="detalharItem($event)"
    >
      <template v-slot:top>
        <v-toolbar flat :class="$vuetify.breakpoint.xs ? 'scroll' : ''">
          <ButtonMain
            v-if="perfilUsuario === 'medico'"
            text="Criar Diagnóstico"
            :primary="true"
            @click.native="exibirDialogDiagnostico = true"
          ></ButtonMain>

          <v-spacer></v-spacer>

          <ButtonMain
            @click.native="abrirFiltro()"
            text="filtrar"
            :primary="true"
          ></ButtonMain>

          <ButtonMain
            v-if="filtroAplicado"
            text="Limpar filtros"
            class="ml-2 warning"
            :primary="true"
            @click.native="
              limparFiltro()
              filtroAplicado = false
            "
          ></ButtonMain>

          <DialogConfirmation
            title="Filtros"
            :scrollable="true"
            :width="720"
            :show="dialogFiltroAberto"
            :key="keyDialogFiltro"
            :tag="'add'"
            @close="closeFiltro()"
            @confirm="
              aplicarFiltro()
              dialogFiltroAberto = false
            "
          >
            <template v-slot:body>
              <v-row class="mt-3">
                <InputField
                  label="Nome do Médico"
                  :cols="12"
                  :valueField="filtro.nomeMedico"
                  @update:value="filtro.nomeMedico = $event"
                />

                <InputField
                  label="CRM"
                  :cols="6"
                  :valueField="filtro.crm"
                  @update:value="filtro.crm = $event"
                />

                <DateField
                  cols="6"
                  label="Data de Criação"
                  :dateFormatted="filtro.data"
                  :date="filtro.data"
                  :valueField="filtro.data"
                  :readonly="true"
                  @dateSelected="filtro.data = $event"
                />
              </v-row>
            </template>
          </DialogConfirmation>
        </v-toolbar>
      </template>

      <template v-slot:[`item.cpf`]="{ item }">
        <span>{{ item.cpf | VMask('###.###.###-##') }}</span>
      </template>

      <!-- <template v-slot:[`item.acoes`]="{ item }">
        <v-row class="justify-center">
          <TooltipMain top text="Criar Receita">
            <ButtonIcon
              style="height: 1.9rem"
              :estiloIcone="'font-size: 1.2rem'"
              class="mx-1"
              :primary="true"
              icon="plus-thick"
              @click.native.stop="abrirCriarReceita(item)"
            />
          </TooltipMain>
        </v-row>
      </template> -->
    </CrudTable>

    <!--
      TODO: não seguir mais o padrão de criar algo dentro do dialog.
      O dialog apenas exibirá o componente.
      Tem um exemplo de como será, na criação de uma receita
    -->
    <DialogConfirmation
      persistente
      tag="add"
      title="Novo Diangóstico"
      :show="exibirDialogDiagnostico"
      :key="keyDialogDiagnostico"
      :width="1440"
      :loading="cadastrandoDiagnostico"
      :scrollable="true"
      @confirm="validarFormDiagnostico()"
      @close="fechaDialogDiagnostico()"
    >
      <template v-slot:body>
        <DiagnosticoPaciente
          :modo="'CRIAR'"
          :diagnostico="{}"
          :paciente="paciente"
          :validar="keyValidarFormDiagnostico"
          @formValue="criarDiagnostico($event)"
          @closeDialog="
            exibirDialogDiagnostico = false
            ++keyDialogDiagnostico
          "
        />
      </template>
    </DialogConfirmation>

    <!-- <DialogConfirmation
      persistente
      tag="add"
      :title="tituloDialogReceita"
      :show="exibirDialogReceita"
      :key="keyDialogReceita"
      :width="1380"
      :exibirAcoes="false"
      :scrollable="true"
      @close="fechaDialogReceita()"
    >
      <template v-slot:body>
        <ReceitaDetalhes
          :modo="'CRIAR'"
          :receita="{}"
          :diagnosticoId="diagnosticoId"
          @closeDialog="fechaDialogReceita()"
        />
      </template>
    </DialogConfirmation> -->
  </v-container>
</template>

<script>
import CrudTable from '@/components/molecules/tables/CrudTable'
import ButtonIcon from '@/components/atoms/buttons/ButtonIcon.vue'
import TooltipMain from '@/components/atoms/TooltipMain.vue'
import InputMaskField from '@/components/atoms/fields/InputMaskField.vue'
import InputField from '@/components/atoms/fields/InputField.vue'
import DateField from '@/components/atoms/fields/DateField.vue'
import SelectField from '@/components/atoms/fields/SelectField.vue'
import DialogConfirmation from '@/components/molecules/dialogs/DialogConfirmation.vue'
import ButtonMain from '@/components/atoms/buttons/ButtonMain.vue'
import DiagnosticoPaciente from './DiagnosticoPaciente.vue'
import agendaService from '@/services/AgendaService'
import authService from '@/services/AuthService'
import globalMethods from '@/mixins/globalMethods'
import colaboradorService from '@/services/ColaboradorService'
//import ReceitaDetalhes from './ReceitaDetalhes.vue'

export default {
  name: 'ListaDiagnosticos',
  components: {
    CrudTable,
    ButtonMain,
    ButtonIcon,
    TooltipMain,
    DialogConfirmation,
    DiagnosticoPaciente,
    InputMaskField,
    InputField,
    SelectField,
    DateField
    //ReceitaDetalhes
  },

  mixins: [globalMethods],

  data() {
    return {
      carregando: false,
      buscando: false,

      exibirDialogDiagnostico: false,
      cadastrandoDiagnostico: false,
      keyValidarFormDiagnostico: false,
      keyDialogDiagnostico: 0,

      keyDialogFiltro: 30,
      dialogFiltroAberto: false,
      filtroAplicado: false,

      exibirDialogReceita: false,
      cadastrandoReceita: false,
      keyValidarFormReceita: false,
      keyDialogReceita: 60,

      diagnosticoId: 0,
      tituloDialogReceita: 'Nova Receita',

      keyPaginacao: 0,
      itensPorPagina: 15,
      statusSelecionado: 0,

      cpfPaciente: '',
      cpfColaborador: '',
      perfilUsuario: authService.perfilUsuario(),
      dadosColaborador: null,

      paciente: null,

      filtro: {
        nomeMedico: '',
        crm: '',
        data: ''
      },
      paginacao: {
        page: 0,
        size: 15,
        total: 0
      },

      diagnosticos: [],

      headers: [
        {
          text: 'ID',
          value: 'diagnostico',
          sortable: false
        },
        {
          text: 'Criado por',
          value: 'medico',
          width: 350,
          sortable: false
        },
        { text: 'CRM', value: 'crm', align: 'center', sortable: false },
        {
          text: 'Data de Criação',
          value: 'data',
          align: 'center',
          sortable: false
        }
        //{ text: 'Ações', value: 'acoes', align: 'center', width: 150, sortable: false },
      ]
    }
  },

  created() {
    if (this.perfilUsuario !== 'medico') {
      const idxColunaRemover = this.headers.findIndex(
        (coluna) => coluna.value === 'acoes'
      )
      if (idxColunaRemover >= 0) {
        this.headers.splice(idxColunaRemover, 1)
      }
    }

    this.getPacienteMedico()
      .then(({ data }) => {
        this.paciente = data
        if (this.paciente) {
          this.checkCpfValido()
        }
      })
      .catch((err) => {
        this.$root.vtoast.show({
          status: 'error',
          text: err.response?.data.mensagemUsuario,
          title: err.response?.data.titulo
        })
      })
  },

  methods: {
    checkCpfValido() {
      const cpfStore = this.$store.getters['auth/cpf']

      if (!cpfStore || cpfStore?.length !== 11) {
        this.$root.vtoast.show({
          status: 'error',
          text: 'Por favor, refaça seu login!',
          title: 'CPF não informado!'
        })
        return
      }

      this.cpfColaborador = cpfStore
      this.receberDadosColaborador()
    },

    receberDadosColaborador() {
      colaboradorService
        .detalharColaboradorPorCpf(this.cpfColaborador)
        .then(({ data }) => {
          this.dadosColaborador = data
          this.filtroDiagnosticos({ page: this.paginacao.page })
        })
        .catch((err) => {
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo
          })
        })
    },

    filtroDiagnosticos(paginacao) {
      this.carregando = true
      const filtro = {
        ...this.filtro,
        ...paginacao,
        size: this.itensPorPagina,
        id: this.paciente.id,
        medico: this.dadosColaborador?.id,
        dependente: this.paciente?.idAssociado ? 'true' : 'false'
      }

      agendaService
        .diagnosticosMedicoPorPaciente(filtro)
        .then(({ data }) => {
          this.carregando = false
          this.paginacao.total = data.totalPages
          this.diagnosticos = data.content.map((item) => ({
            ...item,
            data: `${item?.data || ''} ${item?.hora || ''}`
          }))
        })
        .catch((err) => {
          this.carregando = false
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo
          })
        })
    },

    abrirFiltro() {
      this.dialogFiltroAberto = true
    },

    // abrirCriarReceita(diagnostico) {
    //   if(!diagnostico || !diagnostico.diagnostico) {
    //     this.$root.vtoast.show({
    //       status: 'error' || 'warn',
    //       title: 'Erro!',
    //       text: 'Diagnóstico não encontrado. Por favor recarregue a página.',
    //       time: 30000,
    //     })

    //     return;
    //   }

    //   this.tituloDialogReceita = `Nova Receita - Diagnóstico ${diagnostico.diagnostico}`
    //   this.exibirDialogReceita = true;
    //   this.diagnosticoId = diagnostico.diagnostico
    // },

    closeFiltro() {
      this.dialogFiltroAberto = false

      if (!this.filtroAplicado) {
        this.resetObjeto(this.filtro)
      }
    },

    aplicarFiltro() {
      ++this.keyPaginacao
      this.filtroAplicado = true
      this.paginacao.page = 1
      const paginacao = { page: 0 }
      this.filtroDiagnosticos(paginacao)
    },

    resetObjeto(obj) {
      Object.keys(obj).forEach((key) => (obj[key] = ''))
    },

    limparFiltro() {
      ++this.keyPaginacao
      this.resetObjeto(this.filtro)
      this.paginacao.page = 1
      const paginacao = { page: 0 }
      this.filtroDiagnosticos(paginacao)
    },

    validarFormDiagnostico() {
      this.keyValidarFormDiagnostico = true
      setTimeout(() => (this.keyValidarFormDiagnostico = false), 50)
    },

    validarFormReceita() {
      this.keyValidarFormReceita = true
      setTimeout(() => (this.keyValidarFormReceita = false), 50)
    },

    criarDiagnostico(diagnostico) {
      this.cadastrandoDiagnostico = true

      agendaService
        .cadastrarDiagnosticoMedico(diagnostico)
        .then(() => {
          this.cadastrandoDiagnostico = false
          this.exibirDialogDiagnostico = false
          ++this.keyDialogDiagnostico

          const paginacao = { page: this.paginacao.page }
          this.filtroDiagnosticos(paginacao)
        })
        .catch((err) => {
          this.cadastrandoDiagnostico = false
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo
          })
        })
    },

    detalharItem(item) {
      agendaService
        .diagnosticosMedicoPorId(item.diagnostico)
        .then(({ data }) => {
          this.$emit('detalhar', data)
        })
        .catch((err) => {
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo
          })
        })
    },

    fechaDialogDiagnostico() {
      this.exibirDialogDiagnostico = false
      ++this.keyDialogDiagnostico
    },

    fechaDialogReceita() {
      this.exibirDialogReceita = false
      this.diagnosticoId = 0
      ++this.keyDialogReceita
    },

    page(page) {
      this.paginacao.page = page - 1
      const paginacao = { page: this.paginacao.page }
      this.filtroDiagnosticos(paginacao)
      this.carregando = false
    }
  }
}
</script>

<style scoped>
.btn-buscar {
  margin: auto 0;
  height: 3.3rem !important;
  width: 10rem !important;
}
</style>