import axios from 'axios'

const URL_AGENDA = '/api-agenda'

const atribuirParametros = (filtro) => {
  let parametros = []
  for (const [key, value] of Object.entries(filtro)) {
    if (value || value === 0) parametros.push(`${key}=${value}`)
  }

  if (parametros.length <= 0) return ''
  return parametros.length === 1 ? `?${parametros}` : `?${parametros.join('&')}`
}

export default {
  cadastrarProntuarioMedico(body) {
    const url = `${URL_AGENDA}/prontuario`
    return axios.post(url, body)
  },

  filtroProntuarioMedico(filtro) {
    const parametros = atribuirParametros({ ...filtro })
    const url = `${URL_AGENDA}/prontuario${parametros}`
    return axios.get(url)
  },

  prontuarioMedicoPorId(prontuarioId) {
    const url = `${URL_AGENDA}/prontuario/${prontuarioId}`
    return axios.get(url)
  },

  pacienteProntuarioPorCpf(cpf) {
    const url = `${URL_AGENDA}/prontuario/paciente/${cpf}`
    return axios.get(url)
  },

  comentarParteCorpoDiagnostico(body) {
    const url = `${URL_AGENDA}/detalhe-corpo/corpo`
    return axios.post(url, body)
  },

  comentariosPorProntuario(prontuarioId) {
    const url = `${URL_AGENDA}/detalhe-corpo/prontuario/${prontuarioId}/comentarios`
    return axios.get(url)
  },

  comentariosPorParteCorpoPaciente(pacienteId, filtro) {
    const parametros = atribuirParametros(filtro)
    const url = `${URL_AGENDA}/detalhe-corpo/paciente/${pacienteId}/comentarios/ordenado${parametros}`
    return axios.get(url)
  },

  diagnosticosPorProntuarioMedico(prontuarioId, filtro) {
    const parametros = atribuirParametros({ ...filtro })
    const url = `${URL_AGENDA}/diagnosticos/prontuario/${prontuarioId}${parametros}`
    return axios.get(url)
  },

  diagnosticosMedicoPorId(diagnosticoId) {
    const url = `${URL_AGENDA}/diagnosticos/${diagnosticoId}`
    return axios.get(url)
  },

  cidsPorDiagnosticosMedico(diagnosticoId) {
    const url = `${URL_AGENDA}/diagnosticos/${diagnosticoId}/cids`
    return axios.get(url)
  },

  diagnosticosMedicoPorPaciente(filtro) {
    const parametros = atribuirParametros(filtro)
    const url = `${URL_AGENDA}/diagnosticos/paciente${parametros}`
    return axios.get(url)
  },

  cadastrarDiagnosticoMedico(body) {
    const url = `${URL_AGENDA}/diagnosticos`
    return axios.post(url, body)
  },

  pacientes(filtro) {
    const parametros = atribuirParametros(filtro)
    const url = `${URL_AGENDA}/prontuario/pacientes${parametros}`
    return axios.get(url)
  },

  historicoConsultasPaciente(filtro) {
    const parametros = atribuirParametros(filtro)
    const url = `${URL_AGENDA}/agenda/backoffice/paciente/agendamento${parametros}`
    return axios.get(url)
  },

  cadastrarReceitaMedica(body) {
    const url = `${URL_AGENDA}/receita`
    return axios.post(url, body)
  },

  filtroReceitasMedica(filtro) {
    const parametros = atribuirParametros(filtro )
    const url = `${URL_AGENDA}/receita/listar${parametros}`
    return axios.get(url)
  },

  medicamentos(filtro){
    const parametros = atribuirParametros(filtro)
    const url = `${URL_AGENDA}/medicamento${parametros}`
    return axios.get(url)
  }
}
