import axios from  'axios';

const viaCepAxios = axios.create({
  baseURL : 'https://viacep.com.br',
})

export default {

  buscarCep(cep) {
    const url = `/ws/${cep}/json/`;
    return viaCepAxios.get(url,  { transformRequest: (data, headers) => {
      delete headers.common['Authorization'];
      return data;
    }});
  }
}