export const corpoHumano = {
    data() {
        return {
            PARTES_FRENTE_MASCULINA: [
                {
                    id: 'parte-frente-masculina-1',
                    nome: 'Pulso Direito',
                    selecionada: false,
                    destacada: false,
                    d: 'M27.2148 223.965L41.3171 229.818C41.3171 229.818 50.9507 222.675 44.3763 221.733C37.8019 220.791 31.2109 213.764 31.2109 213.764L26.9827 221.733L26.394 223.254L27.2148 223.957V223.965Z',
                    fill: '#FBD2C2',
                    stroke: '#646463',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-1',
                },
                {
                    id: 'parte-frente-masculina-2',
                    nome: 'Cotovelo Direito',
                    selecionada: false,
                    destacada: false,
                    d: 'M43.4312 169.006C43.4312 169.006 51.1911 153.769 51.8958 154.712C52.6005 155.654 62.7067 163.855 65.9981 163.855C69.2895 163.855 76.5769 163.153 76.5769 163.153C76.5769 163.153 73.0534 183.308 70.6989 183.076C68.3443 182.845 56.8287 173.23 56.8287 173.23L43.4312 172.056V169.006Z',
                    fill: '#FBD2C2',
                    stroke: '#646463',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-2',
                },
                {
                    id: 'parte-frente-masculina-3',
                    nome: 'Pescoço',
                    selecionada: false,
                    destacada: false,
                    d: 'M89.4355 81.9451C91.019 81.9451 107.94 79.1343 112.177 75.2653L116.405 71.3963V54.8704C116.405 54.8704 125.218 62.9556 127.862 63.6583C130.507 64.361 137.38 63.8319 137.38 63.8319C137.38 63.8319 149.194 57.1521 149.542 55.5731C149.89 53.9941 149.542 72.099 149.542 72.099C149.542 72.099 163.114 82.8214 167.699 82.6478C172.283 82.4742 158.712 86.5168 149.368 86.6904C140.025 86.864 138.267 86.6904 138.267 86.6904C138.267 86.6904 136.858 90.5594 133.334 90.3775C129.811 90.1956 128.219 88.6166 128.045 86.6904C127.871 84.7642 103.836 84.8716 103.836 84.8716L89.4438 81.9451H89.4355Z',
                    fill: '#FBD2C2',
                    stroke: '#646463',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-3',
                },
                {
                    id: 'parte-frente-masculina-4',
                    nome: 'Pé Esquerdo',
                    selecionada: false,
                    destacada: false,
                    d: 'M152.013 469.696L158.355 475.673L160.983 488.33L171.753 497.117L187.264 491.496L185.15 478.492C185.15 478.492 171.753 465.488 171.753 459.858C171.753 454.228 158.811 461.536 158.811 461.536L152.013 467.943V469.704V469.696Z',
                    fill: '#FBD2C2',
                    stroke: '#646463',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-4',
                },
                {
                    id: 'parte-frente-masculina-5',
                    nome: 'Pé Direito',
                    selecionada: false,
                    destacada: false,
                    d: 'M112.533 467.935L106.539 475.673L104.773 487.974L97.0132 497.118L89.9662 495.357L82.5627 492.893L78.6827 488.677L81.858 478.831L92.4368 464.768L93.8462 460.197H100.188C100.188 460.197 110.767 461.602 112.525 465.818C114.282 470.035 112.525 467.926 112.525 467.926L112.533 467.935Z',
                    fill: '#FBD2C2',
                    stroke: '#646463',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-5',
                },
                {
                    id: 'parte-frente-masculina-6',
                    nome: 'Cotovelo Esquerdo',
                    selecionada: false,
                    destacada: false,
                    d: 'M191.144 164.202C191.144 164.202 193.433 183.308 195.994 183.068C198.556 182.828 203.133 172.461 209.483 172.023C215.825 171.585 224.29 172.213 224.29 172.213L212.658 154.671L203.63 162.739L191.153 164.202H191.144Z',
                    fill: '#FBD2C2',
                    stroke: '#646463',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-6',
                },
                {
                    id: 'parte-frente-masculina-7',
                    nome: 'Pulso Esquerdo',
                    selecionada: false,
                    destacada: false,
                    d: 'M224.29 231.835C224.29 231.835 236.983 223.262 239.097 223.965C241.211 224.668 235.217 213.772 235.217 213.772L222.591 220.567H219.705C219.705 220.567 222.748 232.852 224.29 231.835Z',
                    fill: '#FBD2C2',
                    stroke: '#646463',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-7',
                },
                {
                    id: 'parte-frente-masculina-8',
                    nome: 'Mão Esquerda',
                    selecionada: false,
                    destacada: false,
                    d: 'M224.29 233.811C224.29 233.811 226.056 253.495 227.465 254.198C228.875 254.901 232.755 272.476 235.225 272.476C237.696 272.476 235.225 256.306 235.225 256.306C235.225 256.306 239.454 277.395 241.219 277.395H245.099V268.252L241.576 255.595L248.623 279.586H252.503V266.491L247.214 251.767L254.617 271.765L259.55 272.121L249.676 238.722C249.676 238.722 256.018 239.077 257.436 241.888C258.845 244.699 265.196 245.054 265.196 242.946C265.196 240.838 262.725 236.267 262.725 236.267L244.743 226.421L239.105 223.957L224.298 231.827V233.795L224.29 233.811Z',
                    fill: '#FBD2C2',
                    stroke: '#646463',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-8',
                },
                {
                    id: 'parte-frente-masculina-9',
                    nome: 'Mão Direita',
                    selecionada: false,
                    destacada: false,
                    d: 'M41.9057 232.844C41.9057 232.844 40.1398 252.528 38.7304 253.231C37.321 253.933 33.441 271.509 30.9704 271.509C28.4998 271.509 30.9704 255.339 30.9704 255.339C30.9704 255.339 26.7422 276.428 24.9764 276.428H21.0964V267.285L24.6199 254.628L17.5729 278.619H13.6929V265.524L18.9823 250.8L11.5788 270.798L6.64588 271.154L16.52 237.755C16.52 237.755 10.1777 238.11 8.75998 240.921C7.35058 243.732 1 244.087 1 241.979C1 239.871 3.47059 235.299 3.47059 235.299L21.4529 225.453L27.0904 222.99L41.8974 230.86V232.827L41.9057 232.844Z',
                    fill: '#FBD2C2',
                    stroke: '#646463',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-9',
                },
                {
                    id: 'parte-frente-masculina-10',
                    nome: 'Pélvis',
                    selecionada: false,
                    destacada: false,
                    d: 'M129.761 257.835L136.145 258.067C136.145 258.067 172.457 215.996 174.572 217.583C176.686 219.17 171.222 204.984 171.222 204.984C171.222 204.984 168.652 209.382 161.995 212.358C155.337 215.326 152.543 216.409 152.543 216.409C152.543 216.409 125.574 218.691 117.284 216.641C108.993 214.59 102.344 210.787 102.344 210.787C102.344 210.787 94.9571 202.702 95.4545 201.851C95.952 200.999 92.6606 209.556 92.6606 209.556L90.6626 217.583C90.6626 217.583 131.361 253.032 129.753 257.835H129.761Z',
                    fill: '#FBD2C2',
                    stroke: '#646463',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-10',
                },
                {
                    id: 'parte-frente-masculina-11',
                    nome: 'Tornozelo Esquerdo',
                    selecionada: false,
                    destacada: false,
                    d: 'M153.779 446.573L152.013 467.943C152.013 467.943 159.06 459.502 163.644 459.502C168.229 459.502 171.753 459.858 171.753 459.858V448.606L170.343 446.498L160.983 446.068L153.77 446.573H153.779Z',
                    fill: '#FBD2C2',
                    stroke: '#646463',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-74',
                },
                {
                    id: 'parte-frente-masculina-12',
                    nome: 'Coxa Esquerda',
                    selecionada: false,
                    destacada: false,
                    d: 'M144.966 339.621C144.966 339.621 136.858 299.898 136.858 293.218C136.858 286.539 136.153 258.067 136.153 258.067C136.153 258.067 170.7 216.822 174.58 217.583C178.46 218.343 173.485 231.356 176.147 236.622C178.808 241.896 184.446 285.489 184.446 286.539C184.446 287.588 185.15 300.245 184.446 313.258C183.741 326.262 176.147 328.378 176.147 328.378C176.147 328.378 161.182 342.085 157.302 342.085C153.422 342.085 144.966 339.621 144.966 339.621Z',
                    fill: '#FBD2C2',
                    stroke: '#646463',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-12',
                },
                {
                    id: 'parte-frente-masculina-14',
                    nome: 'Joelho Esquerdo',
                    selecionada: false,
                    destacada: false,
                    d: 'M145.314 342.085L147.428 356.85C147.428 356.85 158.007 368.102 163.645 368.102C169.282 368.102 176.138 360.364 176.138 360.364V328.37C176.138 328.37 160.793 343.135 160.444 342.077C160.096 341.019 145.745 343.54 144.949 339.613C144.153 335.686 145.737 343.532 145.737 343.532',
                    stroke: '#646463',
                    'stroke-miterlimit': '10',
                    fill: '#FBD2C2',
                    referencia: 'parte-frente-14',
                },
                {
                    id: 'parte-frente-masculina-15',
                    nome: 'Perna Esquerda',
                    selecionada: false,
                    destacada: false,
                    d: 'M147.428 356.85C147.428 356.85 148.838 367.043 147.428 374.781C146.019 382.519 144.526 392.713 147.743 401.501C150.952 410.288 152.013 424.351 152.013 424.351C152.013 424.351 154.832 441.224 154.127 444.737C153.422 448.251 169.639 444.035 170.343 446.498C171.048 448.962 176.147 391.654 176.147 391.654V360.364L166.472 368.102C166.472 368.102 164.507 370.656 160.992 368.102C157.476 365.547 147.436 356.85 147.436 356.85H147.428Z',
                    fill: '#FBD2C2',
                    stroke: '#646463',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-15',
                },
                {
                    id: 'parte-frente-masculina-16',
                    nome: 'Tornozelo Direito',
                    selecionada: false,
                    destacada: false,
                    d: 'M94.1944 446.573C94.1944 446.573 107.94 443.522 110.411 446.068C112.881 448.606 112.525 465.835 112.525 465.835C112.525 465.835 106.547 460.213 103.364 460.213H93.8379L94.1944 446.581V446.573Z',
                    fill: '#FBD2C2',
                    stroke: '#646463',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-52',
                },
                {
                    id: 'parte-frente-masculina-17',
                    nome: 'Coxa Direita',
                    selecionada: false,
                    destacada: false,
                    d: 'M88.2003 328.378C88.2003 326.27 76.5686 313.969 81.1533 281.62C85.738 249.279 89.7341 246.113 90.3807 235.919C91.0274 225.726 90.9693 219.269 90.6709 217.583C90.3807 215.888 130.408 253.305 129.753 258.679C129.098 264.052 129.446 275.998 126.984 299.204C124.513 322.409 121.346 339.63 120.641 340.688C119.937 341.746 116.057 345.26 110.767 343.499C105.478 341.738 88.2003 328.378 88.2003 328.378Z',
                    fill: '#FBD2C2',
                    stroke: '#646463',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-17',
                },
                {
                    id: 'parte-frente-masculina-18',
                    nome: 'Perna Direita',
                    selecionada: false,
                    destacada: false,
                    d: 'M90.3227 360.016V396.929L93.8462 445.052L110.411 446.068L116.057 403.038L120.285 392.704L117.814 360.016L105.304 366.696L96.0017 368.102L90.3227 360.016Z',
                    fill: '#FBD2C2',
                    stroke: '#646463',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-18',
                },
                {
                    id: 'parte-frente-masculina-19',
                    nome: 'Joelho Direito',
                    selecionada: false,
                    destacada: false,
                    d: 'M90.3227 356.85L90.1072 353.998L88.2003 328.378L108.802 342.614L116.853 343.375L120.641 340.68L118.527 355.445L117.814 360.016L105.304 366.696L96.0018 368.102L90.3227 360.016V356.85Z',
                    fill: '#FBD2C2',
                    stroke: '#646463',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-19',
                },
                {
                    id: 'parte-frente-masculina-20',
                    nome: 'Peito',
                    selecionada: false,
                    destacada: false,
                    d: 'M86.7909 145.221C86.7909 145.221 86.8738 145.544 87.0313 146.106C88.0261 149.669 91.8978 162.855 95.2472 165.434C99.1272 168.419 116.048 171.759 121.512 170.18C126.975 168.601 124.687 168.873 124.687 168.873H138.085C138.085 168.873 148.307 173.164 154.127 172.023C159.947 170.882 171.927 160.863 171.927 160.863L179.33 146.627V103.216L161.348 85.9877L138.259 86.6904C138.259 86.6904 135.962 90.8818 133.326 90.3775C130.689 89.8732 128.036 88.7985 128.036 86.6904C128.036 84.5823 103.828 84.8716 103.828 84.8716L89.021 99.1654L86.2022 107.606L85.2654 109.011V144.403L86.7909 145.221Z',
                    fill: '#FBD2C2',
                    stroke: '#696961',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-20',
                },
                {
                    id: 'parte-frente-masculina-23',
                    nome: 'Ombro Direito',
                    selecionada: false,
                    destacada: false,
                    d: 'M63.4115 123.776L65.053 102.919C65.053 102.919 73.5177 84.4004 83.6239 82.995C93.7301 81.5896 103.836 84.8716 103.836 84.8716L89.0294 99.1654L86.2106 107.606L63.4115 123.776Z',
                    fill: '#FBD2C2',
                    stroke: '#646463',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-23',
                },
                {
                    id: 'parte-frente-masculina-24',
                    nome: 'Ombro Esquerdo',
                    selecionada: false,
                    destacada: false,
                    d: 'M161.356 85.9876C161.356 85.9876 177.747 98.1154 178.808 101.282C179.869 104.448 179.869 107.259 179.869 107.259C179.869 107.259 200.496 124.487 201.201 123.785C201.906 123.082 201.906 100.579 198.556 97.5946C195.207 94.6102 191.327 87.2277 182.34 84.7641C173.353 82.3005 167.707 82.656 167.707 82.656L161.365 85.9959L161.356 85.9876Z',
                    fill: '#FBD2C2',
                    stroke: '#646463',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-24',
                },
                {
                    id: 'parte-frente-masculina-25',
                    nome: 'Braço Direito',
                    selecionada: false,
                    destacada: false,
                    d: 'M52.6006 153.306C52.6006 153.306 58.7107 128.935 62.7068 126.124C62.997 125.918 63.37 125.653 63.8011 125.331C69.3144 121.296 85.2654 109.011 85.2654 109.011V144.403L76.5686 163.153L62.7068 162.673L52.5923 153.315L52.6006 153.306Z',
                    fill: '#FBD2C2',
                    stroke: '#646463',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-25',
                },
                {
                    id: 'parte-frente-masculina-26',
                    nome: 'Braço Esquerdo',
                    selecionada: false,
                    destacada: false,
                    d: 'M179.339 109.011V143.642C179.339 143.642 190.44 164.211 191.153 164.211C191.866 164.211 204.02 163.153 205.081 162.276C206.142 161.4 212.658 154.679 212.658 154.679L208.604 136.615L201.574 124.835L179.339 109.011Z',
                    fill: '#FBD2C2',
                    stroke: '#646463',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-26',
                },
                {
                    id: 'parte-frente-masculina-27',
                    nome: 'Antebraço Direito',
                    selecionada: false,
                    destacada: false,
                    d: 'M70.6906 183.068C70.6906 183.068 59.8797 173.462 56.8205 173.222C53.7612 172.99 46.2417 171.816 43.4229 172.048C40.6041 172.279 31.2026 213.764 31.2026 213.764C31.2026 213.764 38.3905 220.559 46.6645 220.559C54.9385 220.559 70.6906 183.06 70.6906 183.06V183.068Z',
                    fill: '#FBD2C2',
                    stroke: '#646463',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-27',
                },
                {
                    id: 'parte-frente-masculina-28',
                    nome: 'Antebraço Esquerdo',
                    selecionada: false,
                    destacada: false,
                    d: 'M195.994 184.945C195.994 184.945 214.416 220.683 219.705 220.567C224.995 220.452 235.217 213.772 235.217 213.772L225.7 175.454L224.29 172.222L209.483 172.031L195.994 183.076V184.953V184.945Z',
                    fill: '#FBD2C2',
                    stroke: '#646463',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-28',
                },
                {
                    id: 'parte-frente-masculina-29',
                    nome: 'Cabeça',
                    selecionada: false,
                    destacada: false,
                    d: 'M119.58 6.00372C119.58 6.00372 122.042 2.49022 132.298 1.08481C142.553 -0.320589 153.422 16.2053 153.422 16.2053V33.7811L156.954 34.1283V43.2717L154.832 49.3397H150.653L153.417 33.8084L149.542 55.5731L137.38 63.8319L127.862 63.6583L116.405 54.8704L114.921 49.3315H111.115L107.592 42.2135V32.417H111.115L111.29 33.252L112.881 15.4943L119.58 6.00372Z',
                    fill: '#FBD2C2',
                    stroke: '#646463',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-29',
                },
                {
                    id: 'parte-frente-masculina-30',
                    nome: 'Abdómen',
                    selecionada: false,
                    destacada: false,
                    d: 'M94.5509 166.492L95.2556 201.297C95.2556 201.297 95.496 202.074 96.1178 203.289C97.9832 206.927 103.273 214.433 115.7 216.417C132.273 219.055 152.543 216.417 152.543 216.417C152.543 216.417 170.526 209.911 171.23 204.992C171.935 200.073 171.23 164.211 171.23 164.211L171.935 160.871C171.935 160.871 157.485 173.172 154.135 172.031C150.786 170.891 143.034 171.965 138.093 168.882C133.16 165.798 124.695 168.882 124.695 168.882C124.695 168.882 119.87 171.395 113.122 170.32C106.373 169.245 96.01 167.518 94.128 164.211C92.2461 160.904 94.5509 166.492 94.5509 166.492Z',
                    fill: '#FBD2C2',
                    stroke: '#646463',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-30',
                },
            ],

            PARTES_TRAS_MASCULINA: [
                {
                    id: 'parte-tras-masculina-1',
                    selecionada: false,
                    destacada: false,
                    nome: 'Ombro Esquerdo',
                    d: 'M53.9973 127.643L73.0383 112.834C73.0383 112.834 79.9759 88.5328 86.3405 84.8131C92.7051 81.0934 64.1525 90.6219 64.1525 90.6219C64.1525 90.6219 47.0156 105.642 53.9973 127.643Z',
                    fill: '#FBD2C1',
                    stroke: '#696961',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-1',
                },
                {
                    id: 'parte-tras-masculina-2',
                    selecionada: false,
                    destacada: false,
                    nome: 'Braço Esquerdo',
                    d: 'M40.6686 162.971C40.6686 162.971 55.2667 181.376 62.8831 175.029C70.4995 168.683 75.5771 148.374 75.5771 148.374L73.0383 112.834L53.9973 127.643C53.9973 127.643 43.2074 143.932 40.6686 162.971Z',
                    fill: '#FBD2C1',
                    stroke: '#696961',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-2',
                },
                {
                    id: 'parte-tras-masculina-3',
                    selecionada: false,
                    destacada: false,
                    nome: 'Cotovelo Esquerdo',
                    d: 'M30.0903 189.414L31.4126 186.867L40.6686 169.106V162.971C40.6686 162.971 49.5544 174.729 55.9014 176.36C62.2484 177.991 64.8224 173.055 64.8224 173.055L60.979 198.722L52.3224 188.145L40.6686 185.606L30.0903 189.414Z',
                    fill: '#FBD2C1',
                    stroke: '#696961',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-3',
                },
                {
                    id: 'parte-tras-masculina-4',
                    selecionada: false,
                    destacada: false,
                    nome: 'Antebraço Esquerdo',
                    d: 'M23.3202 227.916L30.0903 189.414L40.6686 185.607L52.3224 188.145L60.979 198.722L40.6686 232.993H30.0903L23.3202 227.916Z',
                    fill: '#FBD2C1',
                    stroke: '#696961',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-4',
                },
                {
                    id: 'parte-tras-masculina-5',
                    selecionada: false,
                    destacada: false,
                    nome: 'Pulso Esquerdo',
                    d: 'M19.7236 238.493L34.639 245.474L36.8605 238.493L40.6687 232.993H30.0904L23.3203 227.916L19.7236 238.493Z',
                    fill: '#FBD2C1',
                    stroke: '#696961',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-5',
                },
                {
                    id: 'parte-tras-masculina-6',
                    selecionada: false,
                    destacada: false,
                    nome: 'Mão Esquerda',
                    d: 'M9.88578 249.917L4.49084 282.284V284.505H7.18831L9.88578 280.697L12.7419 265.148L10.2031 288.63L10.8378 291.486L14.646 290.217L19.7236 268.639L16.8675 288.63L19.7236 291.486L23.3202 289.899L26.0706 268.956V283.236L28.292 282.918L34.0043 266.735L36.2258 255.311L34.639 245.474L19.7236 238.493L12.4246 241.349L3.22144 250.234L1 255.311L5.76024 254.994L9.88578 249.917Z',
                    fill: '#FBD2C1',
                    stroke: '#696961',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-6',
                },
                {
                    id: 'parte-tras-masculina-7',
                    selecionada: false,
                    destacada: false,
                    nome: 'Mão Direita',
                    d: 'M247.721 249.917L253.116 282.284V284.505H250.419L247.721 280.697L244.865 265.148L247.404 288.63L246.769 291.486L242.961 290.217L237.884 268.639L240.74 288.63L237.884 291.486L234.287 289.899L231.537 268.956V283.236L229.315 282.918L223.603 266.735L221.381 255.311L222.968 245.474L237.884 238.493L245.183 241.349L254.386 250.234L256.607 255.311L251.847 254.994L247.721 249.917Z',
                    fill: '#FBD2C1',
                    stroke: '#696961',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-7',
                },
                {
                    id: 'parte-tras-masculina-8',
                    selecionada: false,
                    destacada: false,
                    nome: 'Nádegas',
                    d: 'M89.5404 216.281C89.5404 216.281 95.8874 212.156 109.216 212.156C122.545 212.156 116.832 215.329 127.94 216.281C139.047 217.233 142.22 211.838 144.125 212.156C146.029 212.473 156.501 210.543 158.405 212.138C160.309 213.733 167.926 218.502 167.926 218.502L173.003 240.715L175.225 261.975C175.225 261.975 168.56 273.081 166.022 274.668C163.483 276.254 159.04 281.966 159.04 281.966L154.914 284.187H141.586L138.412 282.284L134.604 279.745L127.94 272.129L123.497 278.158L120.323 281.332L114.611 284.187H106.677L101.6 282.284L94.618 277.524L92.3966 274.351L89.5404 270.86L87.2749 267.052L83.8282 264.99V236.695L89.5404 216.281Z',
                    fill: '#FBD2C1',
                    stroke: '#696961',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-8',
                },
                {
                    id: 'parte-tras-masculina-9',
                    selecionada: false,
                    destacada: false,
                    nome: 'Coxa Esquerda',
                    d: 'M83.8281 351.142C83.8281 351.142 77.4812 336.863 76.2118 329.564C74.9424 322.266 76.2118 294.024 76.2118 294.024L77.7985 279.745L79.7379 264.99L83.8281 260.071V261.975L87.2749 267.052L96.672 278.916L104.703 283.438L112.716 284.179L120.332 281.323L123.506 278.15V294.968L120.649 327.334L118.745 346.056L116.207 356.21L113.033 359.066L110.177 360.336H98.1177L93.3574 359.066L88.2798 356.528L83.837 351.133L83.8281 351.142Z',
                    fill: '#FBD2C1',
                    stroke: '#696961',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-9',
                },
                {
                    id: 'parte-tras-masculina-10',
                    selecionada: false,
                    destacada: false,
                    nome: 'Coxa Direita',
                    d: 'M144.759 360.344L141.903 358.123L140.316 354.633L138.73 349.556V339.084L136.825 330.516L133.335 312.112V278.291L141.586 284.187H154.914L166.022 274.668L175.225 261.975L180.62 298.784V322.583L178.716 330.199L176.811 339.401C176.811 339.401 176.177 345.748 175.225 347.017C174.273 348.286 172.686 353.681 172.686 353.681C172.686 353.681 169.512 359.075 164.118 360.344C158.723 361.614 144.759 360.344 144.759 360.344Z',
                    fill: '#FBD2C1',
                    stroke: '#696961',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-10',
                },
                {
                    id: 'parte-tras-masculina-11',
                    selecionada: false,
                    destacada: false,
                    nome: 'Braço Direito',
                    d: 'M180.338 144.593L189.505 169.626C189.505 169.626 191.797 176.607 203.028 175.02C214.259 173.434 215.211 162.962 215.211 162.962L203.028 127.634L182.841 112.825L180.338 144.593Z',
                    fill: '#FBD2C1',
                    stroke: '#646463',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-11',
                },
                {
                    id: 'parte-tras-masculina-12',
                    selecionada: false,
                    destacada: false,
                    nome: 'Ombro Direito',
                    d: 'M163.8 81.1022C163.8 81.1022 183.476 83.9581 191.092 90.3045C198.709 96.6509 201.565 105.8 201.565 105.8L203.028 127.643L182.815 111.539L180.338 100.159L178.081 95.0643L163.8 81.1022Z',
                    fill: '#FBD2C1',
                    stroke: '#646463',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-12',
                },
                {
                    id: 'parte-tras-masculina-13',
                    selecionada: false,
                    destacada: false,
                    nome: 'Lombar',
                    d: 'M87.6363 175.029L89.2231 189.309L88.4297 203.271V215.329L103.812 212.394C103.812 212.394 112.628 210.781 117.987 213.178C123.347 215.576 126.088 216.078 126.088 216.078L133.326 216.228L141.004 213.619L144.116 212.156L158.396 212.138L167.917 218.502V189.943L168.869 175.029H87.6275H87.6363Z',
                    fill: '#FBD2C1',
                    stroke: '#646463',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-13',
                },
                {
                    id: 'parte-tras-masculina-14',
                    selecionada: false,
                    destacada: false,
                    nome: 'Cotovelo Direito',
                    d: 'M195.535 196.766L192.362 185.606V173.152C192.362 173.152 200.613 176.298 206.008 175.029C211.403 173.76 215.211 162.971 215.211 162.971L225.366 182.962V188.357L213.624 188.991H210.45L203.028 189.626L197.774 193.751L195.535 196.766Z',
                    fill: '#FBD2C1',
                    stroke: '#646463',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-14',
                },
                {
                    id: 'parte-tras-masculina-15',
                    selecionada: false,
                    destacada: false,
                    nome: 'Antebraço Direito',
                    d: 'M216.48 232.993H226.953L233.934 227.916L230.126 218.502L225.366 188.357L203.028 189.626L195.535 196.766L216.48 232.993Z',
                    fill: '#FBD2C1',
                    stroke: '#646463',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-15',
                },
                {
                    id: 'parte-tras-masculina-16',
                    selecionada: false,
                    destacada: false,
                    nome: 'Pulso Direito',
                    d: 'M222.51 245.474L237.425 238.493L233.934 227.916L226.953 232.993H216.48L222.51 245.474Z',
                    fill: '#FBD2C1',
                    stroke: '#646463',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-16',
                },
                {
                    id: 'parte-tras-masculina-17',
                    selecionada: false,
                    destacada: false,
                    nome: 'Cabeça',
                    d: 'M111.12 55.3288C111.12 55.3288 115.563 59.7712 125.718 61.0405C135.873 62.3098 144.442 58.1846 144.442 58.1846L148.567 55.3288L150.789 44.8572L153.328 29.6258V20.1062L148.885 11.2212C148.885 11.2212 142.855 2.01888 132.224 1.06692C121.593 0.114956 111.12 9.63458 111.12 9.63458L106.043 17.8849V25.1833L111.12 55.3288Z',
                    fill: '#FBD2C1',
                    stroke: '#646463',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-17',
                },
                {
                    id: 'parte-tras-masculina-18',
                    selecionada: false,
                    destacada: false,
                    nome: 'Pescoço',
                    d: 'M94.9354 79.7624H161.579C161.579 79.7624 148.25 77.2239 147.615 71.1948C146.981 65.1657 148.567 55.3287 148.567 55.3287C148.567 55.3287 144.124 60.4059 134.921 60.7232C125.718 61.0405 119.221 59.6302 119.221 59.6302L111.12 55.3287C111.12 55.3287 115.563 66.1176 113.976 70.5601C112.39 75.0026 94.9354 79.7624 94.9354 79.7624Z',
                    fill: '#FBD2C1',
                    stroke: '#646463',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-18',
                },
                {
                    id: 'parte-tras-masculina-19',
                    selecionada: false,
                    destacada: false,
                    nome: 'Perna Esquerda',
                    d: 'M84.1455 382.557C84.1455 382.557 89.5404 379.066 98.1089 380.018C106.677 380.97 111.438 381.208 111.438 381.208L115.246 404.135V422.539L109.851 435.549L108.264 450.781L106.677 453.954C106.677 453.954 104.139 457.127 101.6 457.127H93.3486L87.6363 454.906L86.3669 448.56L82.5587 418.493V397.471L84.1455 382.557Z',
                    fill: '#FBD2C1',
                    stroke: '#646463',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-19',
                },
                {
                    id: 'parte-tras-masculina-20',
                    selecionada: false,
                    destacada: false,
                    nome: 'Perna Direita',
                    d: 'M148.567 453.319C148.567 453.319 152.058 457.127 159.357 457.127C166.656 457.127 169.195 453.319 169.195 453.319L170.782 450.781V441.896L172.686 428.568L174.59 410.481V401.596L173.003 385.413L168.243 379.851H154.597L145.711 383.192L141.586 415.241C141.586 415.241 141.268 422.539 143.807 428.568C146.346 434.597 148.885 451.098 148.885 451.098L148.567 453.319Z',
                    fill: '#FBD2C1',
                    stroke: '#646463',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-20',
                },
                {
                    id: 'parte-tras-masculina-21',
                    selecionada: false,
                    destacada: false,
                    nome: 'Joelho Esquerdo',
                    d: 'M82.5587 379.851L83.8281 367.325L83.0965 352.738C83.0965 352.738 93.3486 359.075 98.1089 360.344C103.636 361.816 115.246 358.44 115.246 358.44V373.672L111.438 381.208L94.618 380.336L84.1455 382.557L82.5587 379.851Z',
                    fill: '#FBD2C2',
                    stroke: '#6A6A61',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-21',
                },
                {
                    id: 'parte-tras-masculina-22',
                    selecionada: false,
                    destacada: false,
                    nome: 'Joelho Direito',
                    d: 'M145.711 383.192L142.855 373.672V360.344L153.01 360.909C153.01 360.909 164.805 362.742 166.022 360.909C167.238 359.075 172.686 353.681 172.686 353.681V379.851L173.003 385.413L168.243 379.851H154.597L145.711 383.192Z',
                    fill: '#FBD2C2',
                    stroke: '#6A6A61',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-22',
                },
                {
                    id: 'parte-tras-masculina-23',
                    selecionada: false,
                    destacada: false,
                    nome: 'Tornozelo Esquerdo',
                    d: 'M88.2711 504.725L87.6364 454.386L93.3486 456.625H101.6L106.677 453.425V499.913L100.33 497.665L95.2527 497.991L88.2711 504.725Z',
                    fill: '#FBD2C2',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-23',
                },
                {
                    id: 'parte-tras-masculina-24',
                    selecionada: false,
                    destacada: false,
                    nome: 'Pé Esquerdo',
                    d: 'M85.7322 486.638H82.5587L78.1158 489.811L75.2597 493.734V495.84V499.331L88.271 504.725L87.6363 488.225L85.7322 486.638Z',
                    fill: '#FBD2C2',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-24',
                },
                {
                    id: 'parte-tras-masculina-25',
                    selecionada: false,
                    destacada: false,
                    nome: 'Calcanhar Esquerdo',
                    d: 'M106.677 500.6V505.36C106.677 505.36 106.36 506.1 104.826 507.211C103.292 508.322 102.552 509.062 102.552 509.062H100.859L96.3105 508.322L93.4544 507.581L91.0214 506.206L88.271 504.725L95.2527 498.062L100.33 497.744L106.677 499.966V500.6Z',
                    fill: '#FBD2C2',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-25',
                },
                {
                    id: 'parte-tras-masculina-26',
                    selecionada: false,
                    destacada: false,
                    nome: 'Calcanhar Direito',
                    d: 'M148.991 499.542C148.991 499.542 150.26 498.273 154.914 499.542C159.569 500.812 167.185 504.725 167.185 504.725L165.07 507.158H162.108L152.799 509.062L148.991 501.658V499.542Z',
                    fill: '#FBD2C2',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-26',
                },
                {
                    id: 'parte-tras-masculina-27',
                    selecionada: false,
                    destacada: false,
                    nome: 'Tornozelo Direito',
                    d: 'M170.993 453.425L167.185 504.725L158.088 500.609L154.914 499.542H148.991V476.272C148.991 476.272 148.567 471.195 148.567 465.695V453.319C148.567 453.319 154.597 457.127 156.977 457.127C159.357 457.127 170.993 453.425 170.993 453.425Z',
                    fill: '#FBD2C2',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-27',
                },
                {
                    id: 'parte-tras-masculina-28',
                    selecionada: false,
                    destacada: false,
                    nome: 'Pé Direito',
                    d: 'M168.525 486.638C168.525 486.638 171.84 484.734 174.59 486.638C177.34 488.542 180.62 493.734 180.62 493.734V498.978L176.494 501.235L167.185 504.725L168.525 486.638Z',
                    fill: '#FBD2C2',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-28',
                },
                {
                    id: 'parte-tras-masculina-29',
                    selecionada: false,
                    destacada: false,
                    nome: 'Costas',
                    d: 'M73.0383 112.834C73.0383 112.834 80.6547 83.0062 92.7139 81.1022C104.773 79.1983 163.8 81.1022 163.8 81.1022C163.8 81.1022 182.841 91.8911 182.841 112.834C182.841 133.777 179.033 152.182 179.033 152.182L168.878 175.029H87.6363L75.5771 148.374L73.0383 112.834Z',
                    fill: '#FBD2C1',
                    stroke: '#6A6A61',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-29',
                },
            ],

            PARTES_FRENTE_FEMININA: [
                {
                    id: 'parte-frente-feminina-1',
                    nome: 'Ombro Direito',
                    selecionada: false,
                    destacada: false,
                    d: 'M95.3668 82.2797C95.3668 82.2797 75.6735 76.5176 71.3537 79.3987C67.0339 82.2797 64.6283 79.3987 61.2657 92.8549C57.903 106.311 58.3858 111.616 58.3858 111.616L76.8593 105.345C76.8593 105.345 79.9933 90.4484 82.3904 88.5333C84.7875 86.6098 95.3583 82.2882 95.3583 82.2882L95.3668 82.2797Z',
                    fill: '#FBD2C2',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-23',
                },
                {
                    id: 'parte-frente-feminina-2',
                    nome: 'Ombro Esquerdo',
                    selecionada: false,
                    destacada: false,
                    d: 'M140.03 83.2458C140.03 83.2458 148.196 79.1699 152.515 77.365C156.835 75.5601 168.846 82.7628 168.846 85.6438C168.846 88.5249 174.131 111.607 174.131 111.607C174.131 111.607 154.921 108.226 153.964 101.015C153.007 93.804 149.161 87.5589 149.161 87.5589L140.039 83.2373L140.03 83.2458Z',
                    fill: '#FBD2C2',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-24',
                },
                {
                    id: 'parte-frente-feminina-3',
                    nome: 'Abdómen',
                    selecionada: false,
                    destacada: false,
                    d: 'M76.8678 200.471C76.8678 200.471 87.4471 214.647 114.704 214.283C141.953 213.919 157.191 200.471 157.191 200.471L149.636 185.922L152.515 161.238L144.35 168.805L117.457 161.043L89.1242 168.762L81.9245 161.238L82.8817 183.176L76.8678 200.471Z',
                    fill: '#FBD2C2',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-30',
                },
                {
                    id: 'parte-frente-feminina-4',
                    nome: 'Pescoço',
                    selecionada: false,
                    destacada: false,
                    d: 'M76.8678 78.5937L94.6553 82.0849L109.266 85.7624L114.696 89.7366L120.82 86.3726L140.03 83.2458L152.515 77.365C152.515 77.365 139.912 77.1532 138.709 77.0769C137.506 77.0006 131.628 76.0346 130.425 72.5519C129.222 69.0693 130.544 58.1382 130.544 58.1382L122.98 64.7477L111.215 64.9849L101.008 56.8163L102.092 71.1114C102.092 71.1114 99.3054 78.7462 76.8678 78.5937Z',
                    fill: '#FBD2C2',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-3',
                },
                {
                    id: 'parte-frente-feminina-5',
                    nome: 'Cotovelo Direito',
                    selecionada: false,
                    destacada: false,
                    d: 'M42.0636 166.602C42.0636 166.602 49.7461 165.881 52.626 166.602C55.5059 167.322 61.9941 177.168 61.9941 177.168L66.3139 157.111H52.8632L48.5434 150.942L42.0636 166.602Z',
                    fill: '#FBD2C2',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-2',
                },
                {
                    id: 'parte-frente-feminina-6',
                    nome: 'Antebraço Direito',
                    selecionada: false,
                    destacada: false,
                    d: 'M29.0957 220.282C29.0957 220.282 35.3383 226.409 42.0637 224.968C48.789 223.528 61.9941 177.168 61.9941 177.168L52.626 166.602H42.0637C42.0637 166.602 34.6183 183.269 35.1011 187.667C35.5839 192.065 33.8984 203.835 33.8984 203.835L29.0957 220.282Z',
                    fill: '#FBD2C2',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-27',
                },
                {
                    id: 'parte-frente-feminina-7',
                    nome: 'Pulso Direito',
                    selecionada: false,
                    destacada: false,
                    d: 'M25.9787 226.892L37.7438 232.9L42.0636 224.968L36.7189 224.723L29.0957 220.282L25.9787 226.892Z',
                    fill: '#FBD2C2',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-1',
                },
                {
                    id: 'parte-frente-feminina-8',
                    nome: 'Mão Direita',
                    selecionada: false,
                    destacada: false,
                    d: 'M15.6535 241.789H12.2908L4.1255 248.271H1.48279L2.20276 242.789L25.9787 226.892L37.7438 232.9L31.5013 266.286L29.0957 269.896L27.6558 266.286L29.0957 250.915L24.7759 272.54L23.8103 274.455H21.896L20.6933 271.303L23.0903 255.719L19.6091 276.378L17.8134 278.539H16.3734L15.4078 277.099L18.2877 252.72L13.7307 271.303H12.0452L9.88525 270.133L15.6535 241.789Z',
                    fill: '#FBD2C2',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-9',
                },
                {
                    id: 'parte-frente-feminina-9',
                    nome: 'Mão Esquerda',
                    selecionada: false,
                    destacada: false,
                    d: 'M217.355 241.789H220.718L228.883 248.271H231.517L230.797 242.789L207.03 226.892L195.265 232.9L201.507 266.286L203.904 269.896L205.344 266.286L203.904 250.915L208.224 272.54L209.19 274.455H211.112L212.307 271.303L209.91 255.719L213.391 276.378L215.195 278.539H216.635L217.592 277.099L214.712 252.72L219.278 271.303H220.955L223.115 270.133L217.355 241.789Z',
                    fill: '#FBD2C2',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-8',
                },
                {
                    id: 'parte-frente-feminina-10',
                    nome: 'Pélvis',
                    selecionada: false,
                    destacada: false,
                    d: 'M70.8794 214.292C70.8794 214.292 79.2818 218.486 84.8044 223.291C90.327 228.095 112.417 254.042 112.417 254.042H119.863L140.513 226.9L155.158 215.851L160.918 214.3L157.182 200.479L148.949 205.843L135.583 211.232L122.954 213.758L114.687 214.292L103.269 213.529L92.3598 210.716L84.9315 207.14L76.8593 200.471L70.8709 213.198V214.283L70.8794 214.292Z',
                    fill: '#FBD2C2',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-10',
                },
                {
                    id: 'parte-frente-feminina-11',
                    nome: 'Tornozelo Esquerdo',
                    selecionada: false,
                    destacada: false,
                    d: 'M124.665 476.002H138.836L140.996 460.631L133.788 458.47L124.665 460.631V476.002Z',
                    fill: '#FBD2C2',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-74',
                },
                {
                    id: 'parte-frente-feminina-12',
                    nome: 'Pé Esquerdo',
                    selecionada: false,
                    destacada: false,
                    d: 'M122.988 490.653L124.665 492.576H145.079V488.738L138.836 476.002H124.665L122.988 478.645V490.653Z',
                    fill: '#FBD2C2',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-4',
                },
                {
                    id: 'parte-frente-feminina-13',
                    nome: 'Tornozelo Direito',
                    selecionada: false,
                    destacada: false,
                    d: 'M92.4869 476.002H107.378L109.055 465.91L107.378 460.631L99.4494 457.266L91.5298 459.427L92.4869 476.002Z',
                    fill: '#FBD2C2',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-52',
                },
                {
                    id: 'parte-frente-feminina-14',
                    nome: 'Pé Direito',
                    selecionada: false,
                    destacada: false,
                    d: 'M109.055 484.289V489.695L106.175 492.576H98.7294L95.4938 491.619L87.9299 490.898L86.49 488.433L92.4869 476.002H107.378L109.055 478.883V484.289Z',
                    fill: '#FBD2C2',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-5',
                },
                {
                    id: 'parte-frente-feminina-15',
                    nome: 'Pulso Esquerdo',
                    selecionada: false,
                    destacada: false,
                    d: 'M191.902 225.189L199.584 223.774L203.184 220.282L209.554 227.256L201.507 228.332L194.545 232.9L191.902 225.189Z',
                    fill: '#FBD2C2',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-7',
                },
                {
                    id: 'parte-frente-feminina-16',
                    nome: 'Antebraço Esquerdo',
                    selecionada: false,
                    destacada: false,
                    d: 'M170.769 174.762L178.697 166.593H189.505L197.187 184.125L203.193 220.274L199.593 223.765L191.91 225.189C191.91 225.189 172.463 192.056 170.777 174.762H170.769Z',
                    fill: '#FBD2C2',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-28',
                },
                {
                    id: 'parte-frente-feminina-17',
                    nome: 'Cotovelo Esquerdo',
                    selecionada: false,
                    destacada: false,
                    d: 'M166.686 157.111L170.769 174.762L178.697 166.602H189.496L185.414 158.433L183.974 148.154L182.771 156.026L178.095 158.187L166.686 157.111Z',
                    fill: '#FBD2C2',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-6',
                },
                {
                    id: 'parte-frente-feminina-18',
                    nome: 'Coxa Esquerda',
                    selecionada: false,
                    destacada: false,
                    d: 'M119.863 254.042L140.513 226.9L155.158 215.851L160.918 214.3C160.918 214.3 165 225.223 165.958 241.077C166.915 256.931 162.358 283.115 162.358 283.115L152.27 336.923H140.742L127.291 344.854L120.811 346.058C120.811 346.058 122.014 291.529 121.768 287.445C121.531 283.361 118.169 273.514 119.846 254.05L119.863 254.042Z',
                    fill: '#FBD2C2',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-12',
                },
                {
                    id: 'parte-frente-feminina-19',
                    nome: 'Coxa Direita',
                    selecionada: false,
                    destacada: false,
                    d: 'M80.239 336.914H88.8871L100.415 341.719L105.938 346.041H111.697V283.581L113.62 258.117L112.417 254.033L84.8044 223.282L70.8794 214.283C70.8794 214.283 66.5595 230.73 66.5595 235.535V266.04L77.3675 320.331L80.2474 336.906L80.239 336.914Z',
                    fill: '#FBD2C2',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-17',
                },
                {
                    id: 'parte-frente-feminina-20',
                    nome: 'Braço Direito',
                    selecionada: false,
                    destacada: false,
                    d: 'M48.5434 151.705C48.5434 151.705 52.0246 157.111 54.066 157.111H66.3139L74.7164 137.41L73.7592 128.19L78.528 114.243L76.8678 105.345L58.3942 111.616L56.1157 126.004L48.5518 150.942V151.705H48.5434Z',
                    fill: '#FBD2C2',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-25',
                },
                {
                    id: 'parte-frente-feminina-21',
                    nome: 'Braço Esquerdo',
                    selecionada: false,
                    destacada: false,
                    d: 'M154.125 103.659L154.413 116.886L157.191 122.64L159.961 143.053L166.686 157.111L178.095 158.187L182.771 156.026L183.974 148.154L178.095 128.19L175.334 115.192L174.131 111.607L164.357 108.997L158.292 106.218L154.125 103.659Z',
                    fill: '#FBD2C2',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-26',
                },
                {
                    id: 'parte-frente-feminina-22',
                    nome: 'Perna Esquerda',
                    selecionada: false,
                    destacada: false,
                    d: 'M124.665 460.63L120.82 407.543L122.743 379.19L124.428 367.42L131.704 373.783L141.233 374.216L149.881 363.581L152.278 376.071L150.355 407.297L140.996 460.63L133.788 458.47L124.665 460.63Z',
                    fill: '#FBD2C2',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-15',
                },
                {
                    id: 'parte-frente-feminina-23',
                    nome: 'Joelho Direito',
                    selecionada: false,
                    destacada: false,
                    d: 'M83.6016 364.056L90.0898 372.945H100.652L108.335 365.979L112.18 356.616L111.697 346.041H105.938L88.8871 336.914H80.239L83.6016 364.056Z',
                    fill: '#FBD2C2',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-19',
                },
                {
                    id: 'parte-frente-feminina-24',
                    nome: 'Perna Direita',
                    selecionada: false,
                    destacada: false,
                    d: 'M91.5297 459.427L85.77 426.998L80.2474 401.535L83.6101 364.064L90.0898 372.953H100.652L108.335 365.988L110.732 384.723L107.369 460.63C107.369 460.63 101.609 457.029 99.4409 457.266C97.2726 457.504 91.5128 459.427 91.5128 459.427H91.5297Z',
                    fill: '#FBD2C2',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-18',
                },
                {
                    id: 'parte-frente-feminina-25',
                    nome: 'Joelho Esquerdo',
                    selecionada: false,
                    destacada: false,
                    d: 'M121.065 357.811L124.428 367.42C124.428 367.42 130.188 374.148 133.076 374.148C135.965 374.148 141.623 374.631 142.749 372.945C143.876 371.258 149.881 363.573 149.881 363.573V346.041L152.278 336.914H140.75L127.3 344.846L120.82 346.049L121.057 357.819L121.065 357.811Z',
                    fill: '#FBD2C2',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-14',
                },
                {
                    id: 'parte-frente-feminina-26',
                    nome: 'Peito',
                    selecionada: false,
                    destacada: false,
                    d: 'M152.532 114.734C151.042 112.421 149.576 111.353 146.002 110.87C148.67 111.073 149.322 111.675 150.118 112.065C151.702 112.844 154.404 117.429 154.404 117.429L154.074 101.846L149.161 87.6351L140.039 83.2458L120.828 86.4149L114.704 89.8298L109.783 85.9235L95.3752 82.2628C95.3752 82.2628 84.779 87.1437 82.4073 88.6096C80.0357 90.0756 76.8763 105.693 76.8763 105.693L78.6804 115.514C79.3072 114.353 80.078 113.387 80.8996 112.565C79.5359 114.183 78.6804 115.675 78.6804 115.675L73.7677 129.377L74.7248 138.969L76.8763 141.765L77.4946 142.257L81.9245 162.068C81.9245 162.068 84.3216 170.101 89.1242 170.101C93.9268 170.101 115.06 159.772 117.457 161.856C119.854 163.941 138.59 169.593 140.03 170.101C141.47 170.618 152.998 166.864 152.515 162.068C152.151 158.399 152.354 148.078 152.465 143.426L152.524 143.341L156.7 134.461L157.174 123.606C157.174 123.606 154.387 117.632 152.515 114.717L152.532 114.734ZM88.6414 108.633C88.8871 108.608 89.768 108.76 91.0385 109.04C90.0644 108.862 89.192 108.735 88.4466 108.658C88.5144 108.65 88.5821 108.633 88.6414 108.633Z',
                    fill: '#FBD2C2',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-20',
                },
                {
                    id: 'parte-frente-feminina-27',
                    nome: 'Seios',
                    selecionada: false,
                    destacada: false,
                    d: 'M77.4607 111.573L72.4802 130.673L79.0023 142.511L87.8198 146.409L111.096 132.275L139.827 145.426L157.106 140.994L158.089 126.479L154.396 114.268L150.051 108.913L118.897 110.362L87.7182 105.481L77.4607 111.573Z',
                    fill: '#FBD2C2',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-seios',
                },
                {
                    id: 'parte-frente-feminina-28',
                    nome: 'Cabeça',
                    selecionada: false,
                    destacada: false,
                    d: 'M139.073 30.9969L136.498 31.4714L135.262 46.2326C135.533 42.9363 137.506 18.4135 135.829 15.4985C134.025 12.3717 128.926 0.84747 116.288 1.4491C103.651 2.05074 96.1037 12.7361 96.0867 16.7018C96.0867 16.7018 95.9935 26.4296 96.29 35.0982L96.0698 32.6747C96.0698 32.6747 93.0798 29.9123 91.5213 31.4714C89.9627 33.0306 92.4784 43.7244 92.4784 43.7244L95.1211 47.5715L97.3996 47.4528C98.9581 52.6218 100.999 56.8247 100.999 56.8247L111.206 64.9934L122.971 64.7561L130.535 58.1466L134.83 47.5799C134.83 47.5799 137.26 47.9443 137.625 47.5799C137.989 47.2156 141.343 39.2926 141.343 39.2926V33.217L139.065 31.0054L139.073 30.9969Z',
                    fill: '#FBD2C2',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-frente-29',
                },
            ],

            PARTES_TRAS_FEMININA: [
                {
                    id: 'parte-tras-feminina-1',
                    selecionada: false,
                    destacada: false,
                    nome: 'Lombar',
                    d: 'M72.2893 204.912C72.2893 204.912 78.838 192.237 79.8942 188.857C80.9504 185.477 77.993 165.197 77.993 165.197L112.638 167.521L144.536 165.197L142.212 188.857L152.141 207.869C152.141 207.869 132.495 199.842 122.777 203.644C113.06 207.446 116.229 209.77 111.37 207.869C106.511 205.968 103.976 203.644 103.976 203.644C103.976 203.644 77.5705 202.799 72.2893 204.912Z',
                    fill: '#FBD2C1',
                    stroke: '#696961',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-13',
                },
                {
                    id: 'parte-tras-feminina-2',
                    selecionada: false,
                    destacada: false,
                    nome: 'Costas',
                    d: 'M78.4155 77.7416C78.4155 77.7416 141.156 78.7978 143.057 77.7416C144.958 76.6854 158.478 92.1063 154.676 104.781C150.873 117.456 144.536 165.197 144.536 165.197L112.638 167.521L77.9931 165.197L69.3319 103.936C69.3319 103.936 67.2194 87.0364 78.4155 77.7416Z',
                    fill: '#FBD2C1',
                    stroke: '#696961',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-29',
                },
                {
                    id: 'parte-tras-feminina-3',
                    selecionada: false,
                    destacada: false,
                    nome: 'Ombro Esquerdo',
                    d: 'M77.3592 77.6888C77.3592 77.6888 62.3606 76.6854 57.5019 85.7689C52.6433 94.8525 49.6858 120.836 49.6858 120.836L69.1734 105.415C69.1734 105.415 67.0081 96.9649 71.0218 89.7826C75.0355 82.6002 77.3592 77.6888 77.3592 77.6888Z',
                    fill: '#FBD2C1',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-1',
                },
                {
                    id: 'parte-tras-feminina-4',
                    selecionada: false,
                    destacada: false,
                    nome: 'Ombro Direito',
                    d: 'M144.958 77.6536C144.958 77.6536 165.449 80.2766 167.773 94.0075C170.097 107.739 172.632 119.568 172.632 119.568L154.676 104.781C154.676 104.781 156.014 99.7112 155.345 94.8525C154.676 89.9939 144.958 77.6536 144.958 77.6536Z',
                    fill: '#FBD2C1',
                    stroke: '#6A6A61',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-12',
                },
                {
                    id: 'parte-tras-feminina-5',
                    selecionada: false,
                    destacada: false,
                    nome: 'Braço Esquerdo',
                    d: 'M39.3347 157.804L49.6858 120.836L69.1734 105.415L71.9724 122.605L56.657 162.662C56.657 162.662 44.4047 167.521 39.3347 157.804Z',
                    fill: '#FBD2C1',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-2',
                },
                {
                    id: 'parte-tras-feminina-6',
                    selecionada: false,
                    destacada: false,
                    nome: ' Braço Direito',
                    d: 'M167.773 163.877L150.953 122.605L154.676 104.781L172.632 119.568C172.632 119.568 184.251 155.48 184.251 158.015C184.251 160.55 172.421 167.627 167.773 163.877Z',
                    fill: '#FBD2C1',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-11',
                },
                {
                    id: 'parte-tras-feminina-7',
                    selecionada: false,
                    destacada: false,
                    nome: 'Antebraço Direito',
                    d: 'M171.153 181.463C171.153 181.463 182.983 220.121 188.264 220.121C193.545 220.121 200.728 216.108 200.728 216.108L191.855 174.915C191.855 174.915 174.322 172.168 171.153 181.463Z',
                    fill: '#FBD2C1',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-15',
                },
                {
                    id: 'parte-tras-feminina-8',
                    selecionada: false,
                    destacada: false,
                    nome: 'Antebraço Esquerdo',
                    d: 'M23.2799 216.319C23.2799 216.319 28.3498 176.182 30.8848 175.76C33.4198 175.337 49.8971 175.971 51.7983 182.097C53.6995 188.223 35.5323 220.121 35.5323 220.121C35.5323 220.121 23.0775 217.903 23.2799 216.319Z',
                    fill: '#FBD2C1',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-4',
                },
                {
                    id: 'parte-tras-feminina-9',
                    selecionada: false,
                    destacada: false,
                    nome: 'Cotovelo Esquerdo',
                    d: 'M31.0961 174.536C31.0961 174.536 52.2032 177.661 51.7983 182.097C51.3934 186.533 56.8683 163.877 56.8683 163.877C56.8683 163.877 46.702 164.88 45.6721 163.877C44.6423 162.874 39.3347 157.804 39.3347 157.804L31.0961 174.536Z',
                    fill: '#FBD2C1',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-3',
                },
                {
                    id: 'parte-tras-feminina-10',
                    selecionada: false,
                    destacada: false,
                    nome: 'Cotovelo Direito',
                    d: 'M170.097 181.252C170.097 181.252 176.17 175.751 179.462 175.223C182.754 174.695 191.855 174.915 191.855 174.915L184.25 159.071L175.475 164.194C175.475 164.194 168.231 164.986 167.773 163.877C167.315 162.768 170.097 181.252 170.097 181.252Z',
                    fill: '#FBD2C1',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-14',
                },
                {
                    id: 'parte-tras-feminina-11',
                    selecionada: false,
                    destacada: false,
                    nome: 'Pulso Direito',
                    d: 'M190.165 228.571L203.897 222.867L200.728 216.108C200.728 216.108 190.183 221.38 188.264 220.121C186.345 218.863 190.165 228.571 190.165 228.571Z',
                    fill: '#FBD2C1',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-16',
                },
                {
                    id: 'parte-tras-feminina-12',
                    selecionada: false,
                    destacada: false,
                    nome: 'Pulso Esquerdo',
                    d: 'M20.1112 222.234L31.0961 227.937L35.5323 220.121L23.2799 216.319L20.1112 222.234Z',
                    fill: '#FBD2C1',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-5',
                },
                {
                    id: 'parte-tras-feminina-13',
                    selecionada: false,
                    destacada: false,
                    nome: 'Mão Esquerda',
                    d: 'M11.7669 231.572C11.7669 231.572 7.33074 254.554 7.96449 257.723C8.59823 260.892 11.7669 257.723 11.7669 257.723L13.3513 245.347C13.3513 245.347 9.54884 266.278 13.3513 266.278C17.1537 266.278 18.4212 248.692 18.4212 248.692C18.4212 248.692 17.7875 262.793 18.4212 263.11C19.055 263.427 20.0056 264.377 21.5899 262.476C23.1743 260.575 24.7587 245.682 24.7587 245.682C24.7587 245.682 22.8574 259.307 24.7587 259.307C26.6599 259.307 31.0961 243.781 31.0961 243.781V227.937L20.1112 222.234C20.1112 222.234 -0.907906 228.888 0.993321 233.007C2.89455 237.126 11.7669 231.572 11.7669 231.572Z',
                    fill: '#FBD2C1',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-6',
                },
                {
                    id: 'parte-tras-feminina-14',
                    selecionada: false,
                    destacada: false,
                    nome: 'Mão Direita',
                    d: 'M210.093 233.139C210.093 233.139 214.529 256.121 213.896 259.29C213.262 262.458 210.093 259.29 210.093 259.29L208.509 246.914C208.509 246.914 212.311 267.845 208.509 267.845C204.706 267.845 203.439 250.259 203.439 250.259C203.439 250.259 204.072 264.36 203.439 264.676C202.805 264.993 201.854 265.944 200.27 264.043C198.686 262.141 197.101 247.249 197.101 247.249C197.101 247.249 199.003 260.874 197.101 260.874C195.2 260.874 190.764 245.347 190.764 245.347V229.504L201.749 223.8C201.749 223.8 222.768 230.455 220.867 234.574C218.965 238.693 210.093 233.139 210.093 233.139Z',
                    fill: '#FBD2C1',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-7',
                },
                {
                    id: 'parte-tras-feminina-15',
                    selecionada: false,
                    destacada: false,
                    nome: 'Pescoço',
                    d: 'M80.8448 77.6536H144.958C144.958 77.6536 127.742 75.8404 127.108 70.7705C126.474 65.7006 126.157 58.0958 126.157 58.0958L107.779 59.6801L97.639 58.0958C97.639 58.0958 98.9065 72.038 97.0053 72.6717C95.104 73.3055 80.8448 77.6536 80.8448 77.6536Z',
                    fill: '#FBD2C1',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-18',
                },
                {
                    id: 'parte-tras-feminina-16',
                    selecionada: false,
                    destacada: false,
                    nome: 'Cabeça',
                    d: 'M92.2522 43.5198C92.2522 43.5198 83.7847 2.96062 112.259 1.05941C140.733 -0.841801 131.861 43.5198 131.861 43.5198L128.692 55.2439L126.157 58.0958L112.118 59.3016L97.639 58.0958L92.2522 43.5198Z',
                    fill: '#FBD2C1',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-17',
                },
                {
                    id: 'parte-tras-feminina-17',
                    selecionada: false,
                    destacada: false,
                    nome: 'Nádegas',
                    d: 'M112.118 256.455C112.118 256.455 101.442 272.299 88.7667 267.863C76.0919 263.427 71.2332 267.863 63.5227 252.653C55.8121 237.443 72.2894 204.912 72.2894 204.912L103.977 203.644L112.673 207.385L122.778 203.644C122.778 203.644 149.606 201.743 152.141 207.869C154.676 213.995 164.182 244.414 158.795 252.019C153.408 259.624 151.507 266.261 138.199 268.646C124.89 271.031 112.673 261.525 112.118 256.455Z',
                    fill: '#FBD2C1',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-8',
                },
                {
                    id: 'parte-tras-feminina-18',
                    selecionada: false,
                    destacada: false,
                    nome: 'Coxa Esquerda',
                    d: 'M72.2893 341.376C72.2893 341.376 97.639 357.079 103.976 344.475C110.314 331.87 107.568 262.37 107.568 262.37L93.8365 269.025L75.37 264.641L63.5225 252.653C63.5225 252.653 61.7269 269.905 63.5225 279.34C65.3181 288.776 72.2893 341.376 72.2893 341.376Z',
                    fill: '#FBD2C1',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-9',
                },
                {
                    id: 'parte-tras-feminina-19',
                    selecionada: false,
                    destacada: false,
                    nome: 'Coxa Direita',
                    d: 'M117.919 343.498C117.919 343.498 114.169 313.879 112.902 299.338C111.634 284.798 116.097 262.81 116.097 262.81C116.097 262.81 137.23 274.235 145.927 266.278C154.623 258.321 158.795 252.019 158.795 252.019L149.712 336.201C149.712 336.201 138.199 355.759 117.919 343.498Z',
                    fill: '#FBD2C1',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-10',
                },
                {
                    id: 'parte-tras-feminina-20',
                    selecionada: false,
                    destacada: false,
                    nome: 'Joelho Esquerdo',
                    d: 'M76.303 371.373L75.4404 343.788C75.4404 343.788 90.351 349.791 96.0547 349.368C101.758 348.946 103.976 345.108 103.976 345.108L102.709 373.274C102.709 373.274 90.0341 363.768 76.303 371.373Z',
                    fill: '#FBD2C1',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-21',
                },
                {
                    id: 'parte-tras-feminina-21',
                    selecionada: false,
                    destacada: false,
                    nome: 'Perna Esquerda',
                    d: 'M83.0629 439.183C83.0629 439.183 75.2467 421.016 76.7255 410.031C78.2042 399.046 76.5406 371.796 76.5406 371.796C76.5406 371.796 82.9837 367.06 91.0463 368.055C99.1089 369.049 102.709 372.641 102.709 372.641C102.709 372.641 106.723 426.086 101.23 436.014C95.7377 445.943 84.1191 439.183 84.1191 439.183H83.0629Z',
                    fill: '#FBD2C1',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-19',
                },
                {
                    id: 'parte-tras-feminina-22',
                    selecionada: false,
                    destacada: false,
                    nome: 'Joelho Direito',
                    d: 'M120.876 372.641L117.919 343.497C117.919 343.497 127.002 349.738 135.241 347.986C143.48 346.235 147.705 339.009 147.705 339.009L146.226 370.951C146.226 370.951 139.677 366.514 136.086 366.937C132.495 367.359 120.876 372.641 120.876 372.641Z',
                    fill: '#FBD2C1',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-22',
                },
                {
                    id: 'parte-tras-feminina-23',
                    selecionada: false,
                    destacada: false,
                    nome: 'Perna Direita',
                    d: 'M120.674 436.648C119.177 436.648 112.893 378.564 120.867 372.641C127.953 367.377 138.11 367.095 138.11 367.095L146.217 370.951C146.217 370.951 148.963 436.648 139.668 439.183C130.374 441.718 120.674 436.648 120.674 436.648Z',
                    fill: '#FBD2C1',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-20',
                },
                {
                    id: 'parte-tras-feminina-24',
                    selecionada: false,
                    destacada: false,
                    nome: 'Tornozelo Esquerdo',
                    d: 'M87.0767 488.192L83.063 439.183C83.063 439.183 99.1266 444.913 101.23 436.014C103.334 427.116 101.23 485.235 101.23 485.235C101.23 485.235 92.3579 480.587 87.0767 488.192Z',
                    fill: '#FBD2C1',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-23',
                },
                {
                    id: 'parte-tras-feminina-25',
                    selecionada: false,
                    destacada: false,
                    nome: 'Tornozelo Direito',
                    d: 'M129.326 483.58C132.284 483.369 137.354 488.192 137.354 488.192L139.677 439.183C139.677 439.183 135.514 439.887 132.336 439.966C129.159 440.046 120.674 437.387 120.674 437.387L121.308 484.706C121.308 484.706 128.261 483.509 128.789 483.545L129.317 483.58H129.326Z',
                    fill: '#FBD2C1',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-27',
                },
                {
                    id: 'parte-tras-feminina-26',
                    selecionada: false,
                    destacada: false,
                    nome: 'Pé Esquerdo',
                    d: 'M83.063 486.291C83.063 486.291 78.4155 479.76 78.4155 477.11C78.4155 474.461 80.7392 471.081 80.7392 471.081L85.5627 469.717L87.0767 488.192L83.063 486.291Z',
                    fill: '#FBD2C1',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-24',
                },
                {
                    id: 'parte-tras-feminina-27',
                    selecionada: false,
                    destacada: false,
                    nome: 'Calcanhar Esquerdo',
                    d: 'M101.23 485.235C101.23 485.235 99.2234 491.255 97.0053 491.572C94.7872 491.889 90.3334 491.572 90.3334 491.572L87.0767 489.354C87.0767 489.354 90.1397 481.01 101.23 485.235Z',
                    fill: '#FBD2C1',
                    stroke: '#646463',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-25',
                },
                {
                    id: 'parte-tras-feminina-28',
                    selecionada: false,
                    destacada: false,
                    nome: 'Calcanhar Direito',
                    d: 'M137.521 489.204C137.521 489.204 135.62 491.704 132.873 491.986C130.127 492.267 127.592 491.986 127.592 491.986L125.057 490.656L121.783 485.507C121.783 485.507 128.965 483.967 130.127 484.31C131.289 484.654 136.676 486.564 137.521 489.204Z',
                    fill: '#FBD2C1',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-26',
                },
                {
                    id: 'parte-tras-feminina-29',
                    selecionada: false,
                    destacada: false,
                    nome: 'Pé Direito',
                    d: 'M138.093 486.713C138.093 486.713 146.877 476.573 146.604 474.672C146.332 472.771 142.952 470.236 142.952 470.236H138.207L138.093 486.713Z',
                    fill: '#FBD2C1',
                    stroke: '#656564',
                    'stroke-miterlimit': '10',
                    referencia: 'parte-tras-28',
                },
            ],


        }
    }
}