<template>
  <v-container>
    <v-row>
      <v-col>
        <TitleH2 class="mt-3 mb-5 mx-3" title="Dados do Paciente" />
        <FormField :fields="fields" :validate="keyValidacaoForm"> </FormField>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="text-end" offset-sm="12">
        <ButtonMain
          v-if="perfil !== 'medico'"
          class="primary mr-5 mb-5"
          style="width: 12rem"
          text="Alterar"
          :loading="alterando"
          @click.native="exibirDialogAvisoEdicao = true"
        ></ButtonMain>
      </v-col>
    </v-row>
    <DialogConfirmation
      tag="edit"
      title="Alterar Dados"
      :show="exibirDialogAvisoEdicao"
      @confirm="alterarDados()"
      @close="exibirDialogAvisoEdicao = false"
    >
      <template v-slot:body>
        <div class="mt-5">
          <h3>
            Esta ação alterará os dados deste paciente.
            (Cerfique-se de ter a permissão do paciente para fazer esta alteração).
          </h3>
          <h3>
            Deseja continuar?
          </h3>
        </div>
      </template>
    </DialogConfirmation>
  </v-container>

</template>

<script>
import TitleH2 from '@/components/atoms/title/TitleH2.vue'
import CrudTable from '@/components/molecules/tables/CrudTable'
import ButtonIcon from '@/components/atoms/buttons/ButtonIcon.vue'
import ButtonMain from '@/components/atoms/buttons/ButtonMain.vue'
import cepService from '@/services/CepService'
import authService from '@/services/AuthService'
import utilService from '@/services/UtilService'
import StatusChip from '@/components/atoms/StatusChip.vue'
import DialogConfirmation from '@/components/molecules/dialogs/DialogConfirmation.vue'
import InputMaskField from '@/components/atoms/fields/InputMaskField.vue'
import InputField from '@/components/atoms/fields/InputField.vue'
import SelectField from '@/components/atoms/fields/SelectField.vue'
import TooltipMain from '@/components/atoms/TooltipMain.vue'
import FormField from '@/components/atoms/forms/FormField.vue'
import globalMethods from "@/mixins/globalMethods.vue";
import associadoService from '@/services/AssociadoService'

export default {
  components: {
    CrudTable,
    ButtonMain,
    ButtonIcon,
    StatusChip,
    DialogConfirmation,
    InputField,
    SelectField,
    InputMaskField,
    TooltipMain,
    FormField,
    TitleH2
  },

  mixins: [globalMethods],

  data() {
    return {
      criandoFormulario: false,
      alterando: false,
      exibirDialogAvisoEdicao: false,
      keyValidacaoForm: 0,
      enderecoCep: null,
      perfil: null,

      validadores: {
        obrigatorio: 'Campo obrigatório',
        invalido: 'Campo inválido'
      },

      listaUfs: [],
      paciente: null,

      fields: {
        nome: {
          label: 'Nome',
          valueField: null,
          value: '',
          cols: 9,
          field: 'InputField',
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },

        dataNascimento: {
          label: 'Data de Nascimento',
          valueField: null,
          cols: 3,
          value: '',
          field: 'InputMaskField',
          maskFormat: '##/##/####',
          rules: [
            (value) => !!value || this.validadores.obrigatorio,
            (value) =>
              value.replace(/[^\d]/g, '').length >= 8 ||
              this.validadores.invalido
          ]
        },
        cpf: {
          label: 'CPF',
          valueField: null,
          cols: 3,
          value: '',
          field: 'InputMaskField',
          maskFormat: '###.###.###-##',
          rules: [
            (value) => !!value || this.validadores.obrigatorio,
            (value) =>
              value.replace(/[^\d]/g, '').length >= 11 ||
              this.validadores.invalido
          ]
        },
        idUfNaturalidade: {
          label: 'UF Naturalidade',
          valueField: null,
          value: '',
          cols: 2,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'sigla',
          clearable: false,
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        cidadeNaturalidade: {
          label: 'Cidade Naturalidade',
          valueField: null,
          value: '',
          cols: 4,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'nome',
          clearable: false,
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        sexo: {
          label: 'Sexo',
          valueField: null,
          value: null,
          cols: 3,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'descricao',
          clearable: false,
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        cor: {
          label: 'Cor',
          valueField: null,
          value: null,
          cols: 3,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'descricao',
          clearable: false,
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        profissao: {
          label: 'Profissão',
          valueField: null,
          value: '',
          cols: 5,
          field: 'InputField',
          key: 5,
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        estadoCivil: {
          label: 'Estado Civil',
          valueField: null,
          value: null,
          cols: 3,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'descricao',
          clearable: false,
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },

        contatosDivisor: {
          cols: 12,
          label: 'Contatos',
          field: 'DividerMain'
        },
        email: {
          label: 'Email',
          valueField: null,
          value: '',
          cols: 7,
          field: 'InputField',
          rules: [
            (value) => !!value || this.validadores.obrigatorio,
            (value) =>
              /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) ||
              this.validadores.invalido
          ]
        },
        telefone: {
          label: 'Telefone ',
          valueField: null,
          value: '',
          cols: 3,
          field: 'InputMaskField',
          maskFormat: '(##) #####-####',
          rules: [
            (value) => !!value || this.validadores.obrigatorio,
            (value) =>
              value.replace(/[^\d]/g, '').length >= 9 ||
              this.validadores.invalido
          ]
        },

        enderecoDivisor: {
          cols: 12,
          label: 'Endereço',
          field: 'DividerMain'
        },
        cep: {
          label: 'CEP',
          valueField: null,
          value: '',
          cols: 3,
          field: 'InputMaskField',
          maskFormat: '##.###-###',
          rules: [
            (value) => !!value || this.validadores.obrigatorio,
            (value) =>
              value.replace(/[^\d]/g, '').length >= 8 ||
              this.validadores.invalido
          ]
        },
        logradouro: {
          label: 'Logradouro',
          value: '',
          valueField: null,
          cols: 6,
          field: 'InputField',
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        numero: {
          label: 'Número',
          valueField: null,
          value: '',
          cols: 3,
          field: 'InputField',
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        bairro: {
          label: 'Bairro',
          valueField: null,
          value: '',
          cols: 6,
          field: 'InputField',
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        complemento: {
          label: 'Complemento',
          valueField: null,
          value: null,
          cols: 6,
          field: 'InputField'
        },
        idUfEndereco: {
          label: 'UF',
          valueField: null,
          value: '',
          cols: 2,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'sigla',
          clearable: false,
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        cidade: {
          label: 'Cidade',
          valueField: null,
          value: '',
          cols: 4,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'nome',
          clearable: false,
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        complemento: {
          label: 'Complemento',
          value: null,
          valueField: null,
          cols: 6,
          field: 'InputField'
        }
      }
    }
  },

  created() {
    this.receberUFs()
    this.receberSexos()
    this.receberCores()
    this.receberEstadosCivis()
    this.dadosPaciente();

    this.perfil = authService.perfilUsuario();
    if(!this.perfil || this.perfil === 'medico') {
      for(const key of Object.keys(this.fields)) {
        this.fields[key]['readonly'] = true;
        this.fields[key]['rules'] = [];
      }
    } else {
      const editaveis = [
        'sexo', 'cor', 'profissao', 'estadoCivil'
      ]

      for(const key of Object.keys(this.fields)) {
        if(!editaveis.some(item => item === key)) {
          this.fields[key]['disabled'] = true;
          this.fields[key]['rules'] = [];
        }
      }
    }
  },

  methods: {
    dadosPaciente() {
      this.getPacienteMedico()
        .then(({ data }) => {
          this.paciente = data

          const pacienteSlug = this.getPacienteSlug()
          if(pacienteSlug.dependente) {
            delete this.fields['idUfNaturalidade']
            delete this.fields['cidadeNaturalidade']
          }
          this.popularFormulario(data)
        })
        .catch((err) => {
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo
          })
        })
    },

    popularFormulario(data) {
      this.criandoFormulario = true
      const replaces = [
        { key: 'idEstadoCivil', replace: 'estadoCivil' },
        { key: 'idCidadeNaturalidade', replace: 'cidadeNaturalidade' },
        { key: 'idCidadeNaturalidade', replace: 'cidadeNaturalidade' },
        { key: 'idSexo', replace: 'sexo' },
        { key: 'idCor', replace: 'cor' },
      ]

      Object.entries(data).map(([key, value]) => {
        if(key === 'telefone') {
          this.fields[key].valueField = this.setTelefone(
            data.ddd, data.telefone,
          )
        } else {
          replaces.forEach(r => {
            if(r.key === key) {
              this.fields[r.replace].valueField = value;
            } else if (this.fields[key]) {
              this.fields[key].valueField = value;
            }
          })
        }
      })

      if(data.idCidadeNaturalidade) {
        this.popularLocalViaCidade(data.idCidadeNaturalidade, 'idUfNaturalidade', 'cidadeNaturalidade')
      }

      if(data.idCidadeEndereco) {
        this.popularLocalViaCidade(data.idCidadeEndereco, 'idUfEndereco', 'cidade')
      }

      if(data.cidade) {
        this.popularLocalViaCidade(data.cidade, 'idUfEndereco', 'cidade')
      }
    },

    alterarDados() {
      this.exibirDialogAvisoEdicao = false
      if (this.verificarSeHaCampoObrigatorioNulo(this.fields, this.validadores)) {
        ++this.keyValidacaoForm;
        this.$root.vtoast.show({
          status: 'error',
          title: 'Erro!',
          text: 'Dados Incompletos',
        });

        return;
      }

      const paciente = this.getPacienteSlug()
      if(!paciente) {
        this.$root.vtoast.show({
          status: 'error',
          text: 'Paciente não encontrado',
          title: 'Erro!'
        })
        return
      }

      if(!paciente?.dependente) {
        const { id, ...body } = {
          ...this.paciente,
          sexo: this.fields.sexo.value,
          estadoCivil: this.fields.estadoCivil.value,
          profissao: this.fields.profissao.value,
          cor: this.fields.cor.value,

          paisNacionalidade: this.paciente.idPaisNacionalidade,
          cidadeNaturalidade: this.paciente.idCidadeNaturalidade,
          cidadeSubsessao: this.paciente.idCidadeSubsecao,
        }

        this.alterarAssociado(body, paciente.id)
        return
      }

      const { id, ...bodyDependente } = {
        ...this.paciente,
        idSexo: this.fields.sexo.value,
        idEstadoCivil: this.fields.estadoCivil.value,
        profissao: this.fields.profissao.value,
        idCor: this.fields.cor.value,
        idCidade: this.fields.cidade.value,

        arquivos: [],
        idParentesco: this.paciente?.idParentesco || null,
        associado: this.paciente?.idAssociado || null
      }
      this.alterarDependente(bodyDependente, paciente.id)
    },

    alterarAssociado(body, associadoId) {
      this.alterando = true
      associadoService
        .atualizarAssociado(body, associadoId)
        .then(({ data }) => {
          this.alterando = false
          this.$root.vtoast.show({
            status: "success",
            text: "Dados atualizados.",
            title: "Sucesso!",
          });

          this.dadosPaciente()
        })
        .catch((err) => {
          this.alterando = false
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo,
            time: 60000
          })
        })
    },

    alterarDependente(body, dependenteId) {
      this.alterando = true
      associadoService
        .atualizarDependente(dependenteId, body)
        .then(({ data }) => {
          this.alterando = false
          this.$root.vtoast.show({
            status: "success",
            text: "Dados atualizados.",
            title: "Sucesso!",
          });

          this.dadosPaciente()
        })
        .catch((err) => {
          this.alterando = false
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo,
            time: 60000
          })
        })
    },

    verificarCep(cep) {
      cepService.buscarCep(cep).then(({ data }) => {
        if (data.erro) {
          this.fields.bairro.valueField = null
          this.fields.logradouro.valueField = null
          this.fields.idUfEndereco.valueField = null
          this.fields.idUfEndereco.value = null
          this.fields.cidade.valueField = null
          this.fields.cidade.value = null
          return
        }

        this.fields.bairro.valueField = data.bairro
        this.fields.logradouro.valueField = data.logradouro
        this.enderecoCep = data
      })
    },

    receberUFs() {
      utilService.ufs().then(({ data }) => {
        this.listaUfs = data
        this.fields.idUfEndereco.items = data
        this.fields.idUfNaturalidade.items = data
      })
    },

    receberSexos() {
      utilService.sexos().then(({ data }) => {
        this.fields.sexo.items = data
      })
    },

    receberCores() {
      utilService.coresPessoa().then(({ data }) => {
        this.fields.cor.items = data
      })
    },

    receberEstadosCivis() {
      utilService.estadosCivis().then(({ data }) => {
        this.fields.estadoCivil.items = data
      })
    },

    receberCidadesPorUF(ufId, fieldCidade) {
      utilService.cidadesPorUf(ufId).then(({ data }) => {
        this.fields[fieldCidade].items = data
      })
    },

    popularLocalViaCidade(cidadeId, fieldUf, fieldCidade){
      utilService.cidadePorId(cidadeId)
        .then(({ data }) => {
          this.fields[fieldUf].valueField = data.uf.id

          utilService.cidadesPorUf(data.uf.id)
            .then(({ data }) => {
              this.fields[fieldCidade].items = data
              this.fields[fieldCidade].valueField = cidadeId
              this.fields[fieldCidade].value = cidadeId
            })
        })
    },
  },

  watch: {
    'fields.cep.value': function (cep) {
      if (this.criandoFormulario) {
        this.criandoFormulario = false
        return
      }

      if (cep.length === 8) {
        this.verificarCep(cep)
      }
    },

    // 'fields.idUfEndereco.value': function (id) {
    //   this.fields.cidade.valueField = null
    //   this.fields.cidade.value = null
    //   this.receberCidadesPorUF(id, 'cidade')
    // },

    'fields.idUfNaturalidade.value': function (id) {
      this.fields.cidadeNaturalidade.valueField = null
      this.fields.cidadeNaturalidade.value = null
      this.receberCidadesPorUF(id, 'cidadeNaturalidade')
    },

    enderecoCep: function (valor) {
      this.listaUfs.filter((uf) => {
        if (uf.sigla === valor.uf) {
          this.fields.idUfEndereco.valueField = uf.id
          this.fields.idUfEndereco.value = uf.id

          utilService.cidadesPorUf(uf.id).then(({ data }) => {
            this.fields.cidade.items = data

            this.fields.cidade.items.filter((cidade) => {
              if (
                cidade.nome ===
                valor.localidade
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .toUpperCase()
              ) {
                this.fields.cidade.value = cidade.id
                this.fields.cidade.valueField = cidade.id
              }
            })
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.btn-buscar {
  margin: auto 0;
  height: 3.3rem !important;
  width: 10rem !important;
}
</style>