var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"main"},[_c('DialogConfirmation',{key:_vm.keyDialogConfirmarCadastro,attrs:{"title":"Atenção!","width":720,"show":_vm.dialogConfirmarCadastroAberto,"tag":'edit'},on:{"close":function($event){_vm.dialogConfirmarCadastroAberto = false
        ++_vm.keyDialogConfirmarCadastro},"confirm":function($event){return _vm.confirmarCadastro()}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('h3',{staticClass:"mt-5"},[_vm._v(" Após criado, o diagnóstico não poderá ser excluído ou editado. Deseja continuar? ")])]},proxy:true}])}),_c('DialogConfirmation',{key:_vm.keyDialogConfirmar,attrs:{"title":"Atenção!","width":720,"show":_vm.dialogConfirmarAberto,"tag":'edit'},on:{"close":function($event){_vm.dialogConfirmarAberto = false
        ++_vm.keyDialogConfirmar},"confirm":function($event){return _vm.confirmarComentario()}},scopedSlots:_vm._u([{key:"body",fn:function(){return [(_vm.modo === 'VER')?_c('h3',{staticClass:"mt-5"},[_vm._v(" Após adicionado, o comentário não poderá ser excluído ou editado. Deseja continuar? ")]):_c('h3',{staticClass:"mt-5"},[_vm._v(" Após o diagnóstico ser criado, os comentários não poderão ser excluídos ou editados. Deseja continuar? ")])]},proxy:true}])}),_c('DialogConfirmation',{key:_vm.keyDialogComentario,attrs:{"title":(_vm.parteCorpoSelecionada && _vm.parteCorpoSelecionada.nome) || '',"scrollable":true,"width":720,"show":_vm.dialogComentarioAberto,"tag":'add',"loading":_vm.comentando},on:{"close":function($event){return _vm.fecharDialogComentario()},"confirm":function($event){return _vm.confirmarAdicaoComentario()}},scopedSlots:_vm._u([{key:"body",fn:function(){return [(_vm.colaborador)?_c('div',{staticClass:"info-medico ml-3"},[_c('div',[_c('span',[_vm._v("Médico: ")]),_vm._v(" "+_vm._s(_vm.colaborador.nome))]),_c('div',[_c('span',[_vm._v("CRM: ")]),_vm._v(" "+_vm._s(_vm.colaborador.numeroConselho))])]):_vm._e(),_c('FormField',{attrs:{"fields":_vm.fieldsComentario,"validate":_vm.keyValidacaoFormComentario}})]},proxy:true}])}),_c('v-row',[_c('div',{staticClass:"form-container col-xl-7 col-lg-7 col-12"},[_c('v-row',[_c('v-col',[_c('TitleH2',{staticClass:"mt-3 mb-5 mx-3",attrs:{"title":"Dados do Paciente"}}),_c('v-form',{ref:"formSelects",class:_vm.modo !== 'VER' ? 'mb-5' : 'mb-1'},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":_vm.modo !== 'VER' ? 6 : 12}},[(_vm.modo !== 'VER')?_c('SelectField',{ref:"selectSecaoProntuarioMedico",attrs:{"label":"Seções do Prontuário","keyName":"descricao","keyValue":"id","items":_vm.secoes,"clearable":false,"rules":_vm.modo !== 'VER'
                        ? [function (value) { return !!value || _vm.validadores.obrigatorio; }]
                        : []},on:{"change":function($event){return _vm.adicionarChip($event, _vm.secoes, _vm.chipsSecao)}}}):_c('h3',{staticClass:"ml-5"},[_vm._v("Seções do Prontuário")]),(_vm.chipsSecao.length)?_c('div',{staticClass:"container-chips"},[_c('v-chip-group',{attrs:{"active-class":"primary--text","column":""}},_vm._l((_vm.chipsSecao),function(chip){return _c('v-chip',{key:chip.id,staticClass:"custom-chip-prontuario",attrs:{"color":"#E9F4FF","text-color":"#1976D2","close":"","disabled":_vm.modo === 'VER'},on:{"click:close":function($event){return _vm.removerChip(chip, _vm.chipsSecao)}}},[_vm._v(" "+_vm._s(chip.descricao)+" ")])}),1)],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":_vm.modo !== 'VER' ? 6 : 12}},[(_vm.modo !== 'VER')?_c('AutocompletePaginadoMain',{attrs:{"tituloItem":"label","placeholder":"Pesquise por código","keyValue":"id","mensagemItemNaoEncontrado":"Nenhum CID encontrada","cols":12,"exibirAvatar":false,"comTemplate":false,"filled":false,"outlined":true,"clearable":false,"items":_vm.CIDS,"keyName":'label',"rules":_vm.modo !== 'VER'
                        ? [function (value) { return !!value || _vm.validadores.obrigatorio; }]
                        : []},on:{"change":function($event){return _vm.adicionarChip($event, _vm.CIDS, _vm.chipsCID)},"pesquisa":function($event){return _vm.buscarCID($event)},"scrollDown":function($event){return _vm.adicionarCIDs()},"onBlur":function($event){$event && $event.clear && _vm.receberCID()},"onClear":function($event){$event && $event.clear && _vm.receberCID()}}}):_c('h3',{staticClass:"ml-5"},[_vm._v("CIDs")]),(_vm.chipsCID.length)?_c('div',{staticClass:"container-chips"},[_c('v-chip-group',{attrs:{"active-class":"primary--text","column":""}},_vm._l((_vm.chipsCID),function(chip){return _c('v-chip',{key:chip.id,staticClass:"custom-chip-prontuario",attrs:{"color":"#E9F4FF","text-color":"#1976D2","close":"","disabled":_vm.modo === 'VER'},on:{"click:close":function($event){return _vm.removerChip(chip, _vm.chipsCID)}}},[_vm._v(" "+_vm._s(chip.codigo)+" - "+_vm._s(chip.descricao)+" ")])}),1)],1):_vm._e()],1)],1)],1),_c('FormField',{attrs:{"fields":_vm.fields,"validate":_vm.keyValidacaoForm}})],1)],1),(_vm.listaComentarios.length)?_c('div',[_c('h3',{staticClass:"mb-4 ml-1"},[_vm._v("Comentários")]),_c('v-row',_vm._l((_vm.listaComentarios),function(item){return _c('v-col',{key:item.idParteDoCorpo,class:{
                'col-xl-6 col-lg-6 col-md-6 col-12 mb-1': !item.detalheAberto,
                'col-12 mb-1': item.detalheAberto
              }},[_c('div',{staticClass:"painel-comentario",class:{
                  'painel-aberto': item.detalheAberto
                }},[_c('div',{staticClass:"painel-header",on:{"click":function($event){return _vm.detalharComentario(item)}}},[_vm._v(" "+_vm._s(item.descricaoParteDoCorpo)+" "),_c('div',{staticClass:"icone"},[_c('v-icon',{attrs:{"color":"#1976d2"}},[_vm._v("mdi-chevron-down-circle")])],1)]),_c('div',{staticClass:"painel-detalhes"},_vm._l((item.comentario),function(comentario){return _c('div',{key:comentario.id,staticClass:"comentario"},[_vm._v(" "+_vm._s(comentario.comentario)+" "),(comentario.itens.length)?_c('div',{staticClass:"container-itens-detalhes"},_vm._l((comentario.itens),function(detalhe){return _c('div',{key:detalhe.chave,staticClass:"item-detalhes"},[_c('span',{staticClass:"detalhe-chave"},[_vm._v(" "+_vm._s(detalhe.chave)+": ")]),_c('span',[_vm._v(" "+_vm._s(detalhe.valor)+" ")])])}),0):_vm._e()])}),0)])])}),1)],1):_vm._e()],1),_c('div',{staticClass:"corpo-container col-xl-5 col-lg-5 col-12"},[_c('div',{staticClass:"controles mr-2"},[_c('button',{staticClass:"btn-controle mr-2",class:{ 'modo-ativo': _vm.modoSVGCorpo.vista === 'FRENTE' },on:{"click":function($event){return _vm.alternarModoApresentacaoCorpo('FRENTE')}}},[_vm._v(" Frente ")]),_c('button',{staticClass:"btn-controle",class:{ 'modo-ativo': _vm.modoSVGCorpo.vista === 'TRAS' },on:{"click":function($event){return _vm.alternarModoApresentacaoCorpo('TRAS')}}},[_vm._v(" Costa ")])]),_c('div',{class:{
            'svg-container': true,
            'pointer-events-none': this.perfilUsuario !== 'medico'
          }},[(_vm.modoSVGCorpo.vista === 'FRENTE')?_c('div',{staticClass:"corpo-vista-frontal"},[(_vm.modoSVGCorpo.corpo === 'MASCULINO')?_c('svg',{staticClass:"svg-corpo",attrs:{"version":"1.1","xmlns":"http://www.w3.org/2000/svg","width":"100%","height":"384px","viewBox":"0 0 272 510","xml:space":"preserve"}},_vm._l((_vm.partesFrenteMasculina),function(parte){return _c('path',{key:parte.id,class:{
                  'parte-selecionada': parte.selecionada,
                  'parte-destacada': parte.destacada
                },attrs:{"vector-effect":"non-scaling-stroke","id":parte.id,"d":parte.d,"fill":parte.fill,"stroke":parte.stroke,"stroke-miterlimit":parte['stroke-miterlimit']},on:{"click":function($event){return _vm.selecionarParte(parte)},"mouseenter":function($event){_vm.parteCorpoMouseEnter = parte},"mouseleave":function($event){_vm.parteCorpoMouseEnter = undefined}}})}),0):_vm._e(),(_vm.modoSVGCorpo.corpo === 'FEMININO')?_c('svg',{staticClass:"svg-corpo",attrs:{"version":"1.1","xmlns":"http://www.w3.org/2000/svg","width":"100%","height":"384px","viewBox":"0 0 272 510","xml:space":"preserve"}},_vm._l((_vm.partesFrenteFeminina),function(parte){return _c('path',{key:parte.id,class:{
                  'parte-selecionada': parte.selecionada,
                  'parte-destacada': parte.destacada
                },attrs:{"vector-effect":"non-scaling-stroke","id":parte.id,"d":parte.d,"fill":parte.fill,"stroke":parte.stroke,"stroke-miterlimit":parte['stroke-miterlimit']},on:{"click":function($event){return _vm.selecionarParte(parte)},"mouseenter":function($event){_vm.parteCorpoMouseEnter = parte},"mouseleave":function($event){_vm.parteCorpoMouseEnter = undefined}}})}),0):_vm._e()]):_vm._e(),(_vm.modoSVGCorpo.vista === 'TRAS')?_c('div',{staticClass:"corpo-vista-traseira"},[(_vm.modoSVGCorpo.corpo === 'MASCULINO')?_c('svg',{staticClass:"svg-corpo",attrs:{"version":"1.1","xmlns":"http://www.w3.org/2000/svg","width":"100%","height":"384px","viewBox":"0 0 272 510","xml:space":"preserve"}},_vm._l((_vm.partesTrasMasculina),function(parte){return _c('path',{key:parte.id,class:{
                  'parte-selecionada': parte.selecionada,
                  'parte-destacada': parte.destacada
                },attrs:{"vector-effect":"non-scaling-stroke","id":parte.id,"d":parte.d,"fill":parte.fill,"stroke":parte.stroke,"stroke-miterlimit":parte['stroke-miterlimit']},on:{"click":function($event){return _vm.selecionarParte(parte)},"mouseenter":function($event){_vm.parteCorpoMouseEnter = parte},"mouseleave":function($event){_vm.parteCorpoMouseEnter = undefined}}})}),0):_vm._e(),(_vm.modoSVGCorpo.corpo === 'FEMININO')?_c('svg',{staticClass:"svg-corpo",attrs:{"version":"1.1","xmlns":"http://www.w3.org/2000/svg","width":"100%","height":"384px","viewBox":"0 0 272 510","xml:space":"preserve"}},_vm._l((_vm.partesTrasFeminina),function(parte){return _c('path',{key:parte.id,class:{
                  'parte-selecionada': parte.selecionada,
                  'parte-destacada': parte.destacada
                },attrs:{"vector-effect":"non-scaling-stroke","id":parte.id,"d":parte.d,"fill":parte.fill,"stroke":parte.stroke,"stroke-miterlimit":parte['stroke-miterlimit']},on:{"click":function($event){return _vm.selecionarParte(parte)},"mouseenter":function($event){_vm.parteCorpoMouseEnter = parte},"mouseleave":function($event){_vm.parteCorpoMouseEnter = undefined}}})}),0):_vm._e()]):_vm._e()])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }